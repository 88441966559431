<template>
    <b-modal v-model="abrir" size="xl" title="Autorizada" hide-footer @hidden="back()">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-2 mt-10">
                        <div class="form-group">
                            <label for="insc_municipal" class="col-form-label">Tipo de Pessoa</label>
                            <div class="col">
                                <b-form-checkbox v-model="value1" value="1" @change="cpf_cnp(1)" id="tipo_pessoa">
                                    Juridica
                                </b-form-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 mt-10">
                        <div class="form-group">
                            <label for="insc_municipal" class="col-form-label">&nbsp;</label>
                            <div class="col">
                                <b-form-checkbox v-model="value2" @change="cpf_cnp(2)" value="2" id="tipo_pessoa2">
                                    Fisica
                                </b-form-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mt-10">
                        <div class="form-group">
                            <label for="insc_municipal" class="col-form-label">{{ cpf_cnpj }}</label>
                            <div class="col">
                                <input type="text" class="form-control text-left" placeholder="Digite"
                                    id="insc_municipal_id" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <b-table striped hover :items="autorizadas" responsive id="doc-itens" :fields="fields">
                         
                            <template #cell(excluir)="row">
                                <button @click="Delete_doc(row.item)" class="btn btn-danger">Excluir</button>
                            </template>
                        
                        </b-table>
                    </div>
                </div>
                <div class="row">
                    <div class="card-body d-flex justify-content-center align-items-center" id="top">
                        <div class="col-md-12 mt-12 text-center"> <!-- Adicionado "text-center" aqui -->
                            <button class="btn btn-secondary" @click="back()">
                                Adicionar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
export default {
    props: {
        abrir1: { type: Boolean }
    },
    data() {
        return {
            abrir: false,
            cpf_cnpj: 'CNPJ',
            value1: false,
            value2: false,
            autorizadas: [
                {
                    tipo_p: '1',
                    cpnj: '123123123',
                    cpf: '312313123123',
                },
                {
                    tipo_p: '2',
                    cpnj: '123123123',
                    cpf: '312313123123',
                },
            ],
            fields: [
                { label: "Tipo de Pessoa", key: "tipo_p", tdClass: 'text-center', thClass: 'text-center' },
                { label: "CPF", key: "cpnj", tdClass: 'text-center', thClass: 'text-center' },
                { label: "CNPJ", key: "cpf", tdClass: 'text-center', thClass: 'text-center' },
                // { label: "Série", key: "serie", tdClass: 'text-center', thClass: 'text-center', formatter: "formatterCurrBR" },
                // { label: "Criado", key: "data_criacao", tdClass: 'text-right', thClass: 'text-center' },
                { label: "Ações", key: "excluir", tdClass: 'text-center', thClass: 'text-center' },
            ],
        }
    },
    created() {
        this.abrir = this.abrir1
    },
    methods: {
        cpf_cnp(number) {

            if (number === 1 && this.value1) {
                this.cpf_cnpj = 'CNPJ'
                this.value2 = false
            }
            if (number === 2 && this.value2) {
                this.cpf_cnpj = 'CPF'
                this.value1 = false
            }

        },
        back() {
            this.$emit('back')
        }
    }
}
</script>

<style></style>