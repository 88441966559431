<script>
/**
 * Widget Component
 */
export default {
  props:['valor','pagamento'],
  data() {
    return {
      statData: [
        {
          title: "Total Produtos",
          icon: "ri-stack-line",
          value: "0,00",
          subvalue: ""
        },
        {
          title: "Desconto",
          icon: "ri-store-2-line",
          value: "0,00",
          subvalue: ""
        },
        {
          title: "Frete",
          icon: "ri-briefcase-4-line",
          value: "0,00",
          subvalue: ""
        },
        {
          title: "Total liquido",
          icon: "ri-briefcase-4-line",
          value: "0,00",
          subvalue: ""
        }
      ]
    };

  },
  watch: {
    valor(novoValor) {
      this.statData[1].value = novoValor;
      this.statData[1].subvalue = novoValor;
    },
    pagamento(novoPagamento){
      this.statData[2].value = novoPagamento;
      this.statData[2].subvalue = novoPagamento;
    }
  }
};
// ola
</script>
<template>
  <div class="row">
    <div class="col-md-3" v-for="(data, index) in statData" :key="index">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">{{data.title}}</p>
              <h4 class="mb-0">{{data.value}}</h4>
            </div>
            <div class="text-primary">
              <i :class="`${data.icon} font-size-24`"></i>
            </div>
          </div>
        </div>

        <div class="card-body border-top py-3">
          <div class="text-truncate">
            <span class="badge badge-soft-success font-size-11">
              <i class="mdi mdi-menu-up"></i>
              {{data.subvalue}}
            </span>
            <span class="text-muted ml-2">-Informação</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</template>