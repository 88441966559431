<script>
import moment from 'moment';
import appConfig from "@/app.config";
import { http } from '@/helpers/easyindustriaapi/config';
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import List from "./list";
import EntradaBalanca from "./entrada-balanca";
import InsertCompra from "./insert-compra";
import EditCompra from "./edit-compra";
import Teste from "./teste";

import Manifesto from "./manifesto.vue"

export default {
  page: {
    title: "Pedidos",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Manifesto, Layout, PageHeader, List, EntradaBalanca, InsertCompra, EditCompra, Teste },
  data() {
    return {
      loader: {
        get: false
      },
      currentUser: null,
      currentEmpresa: null,
      submitted: false,
      Lista_Produtos: [],
      listPedidos: [],
      currentPedido: {
        id: null,
        pessoa_id: null,
        nnf: null,
        tpnf: 0,
        tipopgto_id: null,
        qtde_parcelas: 1,
        historicopdr_id: null,
        tpmovimento_id: null,
        loc_id: null,
      },
      searchCompras: {},
      titleBody: "Listagem dos Pedidos",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Pedidos",
          href: "/financeiro/pedidos",
          active: true,
        }
      ],
      inserindo: false,
      editando: false,
      hideSearches: false,
      insertingCompra: false,
      editingCompra: false,
      entradaBalancaVisible: false,
      itenVisible: false,
      listTabelas: undefined,
      xml: false,
      pedido: false,
      abrir: false,
      historicos:[],
      movimentos:[],
      estoques:[],
      caixas:[],
      ct_custos:null,
      ct_resultados:null,
      pagamentos:[],
      pessoas:[]
    }
  },
  computed: {
    isHide() {
      return (!this.inserindo && !this.editando && !this.xml && !this.pedido);
    },
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem('user'));
    this.currentEmpresa = JSON.parse(localStorage.getItem('currentEmpresa'));
    this.searchCompras.fromData = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');
    this.searchCompras.toData = moment().endOf('month').format('YYYY-MM-DD HH:mm:ss');
    this.currentPedido.dh_saient = this.searchCompras.toData;
    this.currentPedido.dataemissao = this.searchCompras.toData;
  },
  mounted() {
    this.getData();// Lemvbrar que posso pegar o vetor de produtos pelo metodo get, 
    // utilizando o Axios para fazer a requisão para produtos;
    this.getData_produto();
    this.get_historicos();
    this.get_tipo_movimento();
    this.get_estoques();
    this.get_caixas();
    this.get_cent_custo();
    this.get_cent_resultados();
    this.get_tipo_pagamento();
    this.get_pessoas();
    //this.getData_produto();
    //console.log(this.currentEmpresa)
    //console.log(this.currentUser)
  },
  methods: {
    
    async get_pessoas() {
     // console.log("entrando em pressoas")
      try {
        let response = await http.get("/pessoa?empresa_id="+this.currentEmpresa.id)

        if (response.status === 200) {
          this.pessoas = response.data; //
          // console.log(this.pessoas);
        } else {
          this.pessoas = []
          this.makeToast("danger", "Erro ao carregar lista");
          //this.offLoader();
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    makeToast(variant = null, message = 'Error generic') {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: 'b-toaster-bottom-right',
        variant: variant,
        solid: true,
        appendToast: true
      });
    },
    changeHomeTitle(newTitle) {
      this.titleBody = newTitle;
    },
    importarXML() {
      this.abrir = true
      this.xml = !this.xml
    },
    // usado para alterar o variavel boelana de pedido;
    func_ped() {

      this.pedido = !this.pedido
    },
    back() {
      this.abrir = false;
      this.changeHomeTitle('Listagem dos Pedidos');
      this.inserindo = false;
      this.insertingCompra = false;
      this.editingCompra = false
      this.entradaBalancaVisible = false;
      this.itenVisible = false;
      this.editando = false;
      this.xml = false;
      this.pedido = false;
    },
    onLoader() {
      if (document.getElementById("preloader").style.display === "none") {
        document.getElementById("preloader").style.display = "block";
        document.getElementById("status").style.display = "block";
      }
    },
    offLoader() {
      if (document.getElementById("preloader").style.display === "block") {
        setTimeout(function () {
          document.getElementById("preloader").style.display = "none";
          document.getElementById("status").style.display = "none";
        }, 2500);
      }
    },
    searchInputs() {
      this.hideSearches = !this.hideSearches;
    },
    clearNewPedido() {
      let today = new Date();
      this.currentPedido.id = null;
      this.currentPedido.pessoa_id = null;
      this.currentPedido.nnf = null;
      this.currentPedido.tpnf = 0;
      this.currentPedido.tipopgto_id = null;
      this.currentPedido.qtde_parcelas = 1;
      this.currentPedido.historicopdr_id = null;
      this.currentPedido.tpmovimento_id = null;
      this.currentPedido.loc_id = null;
      this.currentPedido.dh_saient = moment(today).format('yyyy-MM-ddThh:mm');
      this.currentPedido.dataemissao = moment(today).format('yyyy-MM-ddThh:mm');
    },
    async getData_produto() {
      this.onLoader();

      try {
        let response = await http.get('/produto?empresa_id=' + this.currentEmpresa.id);

        if (response.status === 200) {

          this.Lista_Produtos = response.data;
          // console.log(this.Lista_Produtos)
          setTimeout(() => {
            this.offLoader();
          }, 2000);// tempo para carregar a tela;
        } else {
          this.Lista_Produtos = []; // zera o array;
          this.makeToast('danger', 'Erro ao carregar lista');
          this.offLoader();
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast('danger', 'Destino URL não encontrado!');
          } else {
            this.makeToast('danger', error.response);
          }
        }
      }
    },
    async get_caixas(){
      try {
        // bug , aqui , pois não da para pesquisar por emepresa_id 
        // e  só consigo fazer o get por id ou estado ou nome ;   
        let response = await http.get('/movimento-caixa?empresa_id=' + this.currentEmpresa.id);

        if (response.status === 200) {
          this.caixas = response.data;
          //console.log("ibge");
        }
      } catch (error) {
        let messageErro = error.response.data;
        switch (error.response.status) {
          case 406:
            this.makeToast('danger', 'Erro 406: ' + (messageErro.tipo) ? messageErro.tiponome : messageErro.tpag);
            this.offLoader();
            break;
          case 404:
            this.makeToast('danger', 'Erro 404: endpoint não encontrado ou servidor fora do ar');
            this.offLoader();
            break;
        
          default:
            this.makeToast('danger', error.message);
            this.offLoader();
            break;
        }
      }
    },
    async get_historicos() {
      try {
        await http.get("/historicopadrao?empresa_id=" + this.currentEmpresa.id)
          .then(res => {
            this.historicos = res.data ? res.data : null;
            // console.log(this.centtros)
          })

      } catch (error) {
        // this.offLoader();
        if (error.response && error.response.status === 403) {
          this.error('Usuário não tem permissão!');
        }
      }
    },
    async get_cent_custo() {
      try {
        await http.get("/centrocusto?empresa_id=" + this.currentEmpresa.id)
          .then(res => {
            this.ct_custos = res.data ? res.data : null;
            // console.log(this.centtros)
          })

      } catch (error) {
        // this.offLoader();
        if (error.response && error.response.status === 403) {
          this.error('Usuário não tem permissão!');
        }
      }
    },
    async get_cent_resultados() {
      try {
        await http.get("/centroresultado?empresa_id=" + this.currentEmpresa.id)
          .then(res => {
            this.ct_resultados = res.data ? res.data : null;
            // console.log(this.centtros)
          })

      } catch (error) {
        // this.offLoader();
        if (error.response && error.response.status === 403) {
          this.error('Usuário não tem permissão!');
        }
      }
    },
    async get_tipo_movimento() {
      try {
        await http.get("/tipomovimento?empresa_id=" + this.currentEmpresa.id)
          .then(res => {
            this.movimentos = res.data ? res.data : null;
            // console.log(this.centtros)
          })

      } catch (error) {
        // this.offLoader();
        if (error.response && error.response.status === 403) {
          this.error('Usuário não tem permissão!');
        }
      }
    },
    async get_tipo_pagamento() {
      try {
        await http.get("/tipopagamento?empresa_id=" + this.currentEmpresa.id)
          .then(res => {
            this.pagamentos = res.data ? res.data : null;
            // console.log(this.centtros)
          })
      } catch (error) {
        // this.offLoader();
        if (error.response && error.response.status === 403) {
          this.error('Usuário não tem permissão!');
        }
      }
    },
    async get_estoques() {
      try {
        await http.get("/localestoque?empresa_id=" + this.currentEmpresa.id)
          .then(res => {
            this.estoques = res.data ? res.data : null;
            // console.log(this.centtros)
          })

      } catch (error) {
        // this.offLoader();
        if (error.response && error.response.status === 403) {
          this.error('Usuário não tem permissão!');
        }
      }
    },
    async getData() {
      this.onLoader();

      try {
        let toParams = {
          fromNNf: this.searchCompras.fromNNf,
          toNNf: this.searchCompras.toNNf,
          fromData: (this.searchCompras.fromData) ? moment(this.searchCompras.fromData).format('yyyy-MM-DD HH:mm:ss') : null,
          toData: (this.searchCompras.toData) ? moment(this.searchCompras.toData).format('yyyy-MM-DD HH:mm:ss') : null,
          destinatario: this.searchCompras.destinatario
        };

        console.log('Carregando dados...');
        let response = await http.get('/pedido/list?empresa_id=' + this.currentEmpresa.id, {
          params: toParams
        });
        console.log('Validando dados...');

        if (response.status === 200) {
          this.listPedidos = response.data;
          setTimeout(() => {
            this.offLoader();
          }, 2000);
          // this.searchInputs();
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast('danger', 'Erro 406: ' + (messageErro.nnf) ? messageErro.pessoa_id : messageErro.tipo_id);
            this.offLoader();
            break;
          case 404:
            this.makeToast('danger', 'Erro 404: endpoint não encontrado ou servidor fora do ar');
            this.offLoader();
            break;

          default:
            this.makeToast('danger', error.message);
            this.offLoader();
            break;
        }
        this.loader.get = false;
      }

      this.loader.get = false;
    },
    incluirEntradaBalanca() {
      this.entradaBalancaVisible = !this.entradaBalancaVisible;
    },
    async insertCompra() {
      this.onLoader();
      await this.getDadosGerais();
      this.clearNewPedido();
      this.inserindo = !this.inserindo;
      this.insertingCompra = !this.insertingCompra;
      this.offLoader();
    },
    async editCompra(pedido) {
      this.onLoader();
      this.currentPedido = pedido;
      await this.getDadosGerais();
      this.editando = !this.editando;
      this.editingCompra = !this.editingCompra;
      this.offLoader();
    },
    edit() {
      this.editando = !this.editando;
    },
    async entradaBalanca() {
      this.onLoader();
      await this.getDadosGerais();
      this.inserindo = !this.inserindo;
      this.entradaBalancaVisible = !this.entradaBalancaVisible;
      this.offLoader();
    },
    async insertItem() { // criado por vinicus
      this.onLoader();
      this.inserindo = !this.inserindo;
      this.itenVisible = !this.itenVisible;
      this.offLoader();
    },
    async getDadosGerais() {
      try {
        if (this.listTabelas == undefined) {
          await http.get('/tabelas/dadoscompras?empresa_id=' + this.currentEmpresa.id)
            .then(res => {
              this.listTabelas = res.data;
            })
            .finally(() => {
            });
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          this.error('Usuário não tem permissão!');
        }
      }
    },
    async doPost() {
      let response = await http.post('/pedido/?empresa_id=' + this.currentEmpresa.id, this.currentPedido).catch((error) => {
        this.error(error.response);
        this.makeToast('danger', error.response);
      });

      if (response.status === 200) {
        this.getData();
        this.back();
        this.offLoader();
        this.makeToast('success', 'Pedido incluído');
      }
    },
    async doPut() {
      let response = await http.put('/pedido/?empresa_id=' + this.currentEmpresa.id, this.currentPedido).catch((error) => {
        this.error(error.response);
        this.makeToast('danger', error.response);
      });

      if (response.status === 200) {
        this.getData();
        this.back();
        this.offLoader();
        this.makeToast('success', 'Pedido alterado');
      }
    },
  },
}
</script>

<template>
  <Layout>
    <PageHeader :title="titleBody" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <button v-if="isHide" class="btn btn-light" @click="searchInputs()">
                  <span class="fa-sm fa" :class="(hideSearches) ? 'fa-chevron-up' : 'fa-chevron-down'"></span> Busca
                  avançada
                </button>
              </div>
              <div class="col-sm-12 col-md-12 text-md-right">
                <button v-if="isHide" class="btn btn-primary mr-1 " @click="func_ped()">+ Incluir Pedido</button>
                <button v-if="isHide" class="btn btn-primary mr-1" @click="entradaBalanca()">+ Incluir Pesagem</button>
                <button v-if="isHide" class="btn btn-success mr-1" @click="insertCompra()">+ Incluir Compra</button>
                <button v-if="isHide" class="btn btn-success " @click="importarXML()">+ Importar XML</button>
                <button v-if="!isHide" class="btn btn-secondary" @click="back()">Voltar</button>
              </div>
            </div>
          </div>
          <div class="card-body" v-if="hideSearches">
            <!-- Start Card -->
            <div class="card border border-primary">
              <div class="card-body">
                <h4 class="card-title">Filtros</h4>
                <p class="card-title-desc">Use esses campos para filtrar os registros</p>
                <form class="needs-validation" name="search">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="searchCompras-status">Nº Documento</label>
                        <div class="row">
                          <div class="col-md-6">
                            <input id="searchCompras-fromNNf" v-model="searchCompras.fromNNf" type="number"
                              class="form-control" placeholder="Nº NF" />
                          </div>
                          <div class="col-md-6">
                            <input id="searchCompras-toNNf" v-model="searchCompras.toNNf" type="number"
                              class="form-control" placeholder="Nº NF" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="searchCompras-fromData">Data saída</label>
                        <div class="row">
                          <div class="col-md-6">
                            <input id="searchCompras-fromData" v-model="searchCompras.fromData" type="datetime-local"
                              class="form-control" placeholder="dd/mm/aaaa hh:mm" />
                          </div>
                          <div class="col-md-6">
                            <input id="searchCompras-toData" v-model="searchCompras.toData" type="datetime-local"
                              class="form-control" placeholder="dd/mm/aaaa hh:mm" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Fornecedor</label>
                        <input id="searchCompras-destinatario" v-model="searchCompras.destinatario" type="text"
                          class="form-control" placeholder="Nome do Fornecedor" />
                      </div>
                    </div>
                  </div>
                  <button @click="getData" class="btn btn-primary" type="button">OK</button>
                </form>
              </div>
            </div>
            <!-- End Card -->
          </div>
          <div class="card-body" v-if="!isHide">
            <Manifesto v-if="xml" @back="back" :abrir="abrir" />
            <EntradaBalanca :newCompra="currentPedido" :oldItems="items" v-if="entradaBalancaVisible"
              :listTabelas="listTabelas" />
            <InsertCompra v-if="insertingCompra" :newPedido="currentPedido" :oldItems="items" :listTabelas="listTabelas"
              @newTitle="changeHomeTitle" :currentEmpresa="currentEmpresa" :onLoader="onLoader" :offLoader="offLoader"
              @doPost="doPost" />
            <EditCompra v-if="editingCompra" :currentPedido="currentPedido" :oldItems="items" :listTabelas="listTabelas"
              @newTitle="changeHomeTitle" :currentEmpresa="currentEmpresa" :onLoader="onLoader" :offLoader="offLoader"
              @doPut="doPut" />
            <!-- <InsertPedido v-if="itenVisible" 
               @newTitle="changeHomeTitle" :currentEmpresa="currentEmpresa"
              :onLoader="onLoader" :offLoader="offLoader" @doPost="doPost" /> -->
              <!-- :currentUser="currentUser" -->
              <!-- :currentEmpresa="currentEmpresa" -->
            <Teste :Lista_Produtos="Lista_Produtos" v-if="pedido" :p1="pessoas" :empresa_current="currentEmpresa" :currentUser="currentUser"   :pagamentos="pagamentos" :ct_resultados=ct_resultados :ct_custos="ct_custos" :caixas="caixas" :estoques="estoques" :movimentos="movimentos" :historicos="historicos"  />
            
          </div>
          <List v-if="isHide" :currentEmpresa="currentEmpresa" :listPedidos="listPedidos" :isBusy="loader.get"
            @editCompra="editCompra" />
        </div>
      </div>
    </div>
  </Layout>
</template>