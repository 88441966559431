<template>
    <b-modal v-model="abrir1" size="xl" title="Manifestos" hide-footer @hidden="voltar()">
        <div class="card">
            <div class="card-head">
                <div class="col-md-12 mt-10">
                    <button class="btn btn-secondary" id="butt">
                        Sincronizar
                    </button>
                </div>
            </div>

            <div class="card-body">
                <Tast />
                <List />
                <b-table striped hover :items="manifestos" responsive>
                </b-table>
            </div>

        </div>
    </b-modal>
</template>
<script>
import Tast from './widget.vue'
import List from './list.vue'
export default {
    name: "manifesto",
    components: {
        Tast,
        List
    },
    props: {
        abrir: { type: Boolean }
    },
    created() {
        this.abrir1 = this.abrir
    },
    data() {
        return {
            cnpj: '',
            abrir1: null,
            manifestos:[
                {
                    NFE:1,
                    Data: '10/04/2023',
                    valor:23,
                    situação:1
                }
            ]
        }
    },
    methods: {
        voltar() {
            this.$emit('back')
        }
    }
}
</script>

<style></style>