
<script>
import Multiselect from "vue-multiselect";
export default {
    name: "documentos",
    components: {
        Multiselect
    },
    props: {
        abrir_modal: { type: Boolean }
    },
    data() {
        return {
            name: 'doc',
            abrir: false,
            series: [
                "1",
                "2",
                "3",
            ]
        }
    },
    created() {
        this.abrir = this.abrir_modal
    },
    methods: {
        back() {
            this.$emit('back')
        }
    }
}
</script>
<template>
    <b-modal v-model="abrir" size="xl" title="Adicionar Documento Referenciado" hide-footer @hidden="back()">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-4 mt-10">
                        <div class="form-group">
                            <label for="insc_municipal" class="col-form-label">Tipo de Documento</label>
                            <div class="col">
                                <multiselect :options="series">

                                </multiselect>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mt-10">
                        <div class="form-group">
                            <label for="insc_municipal" class="col-form-label">Modelo</label>
                            <div class="col">
                                <multiselect :options="series">

                                </multiselect>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mt-10">
                        <div class="form-group">
                            <label for="insc_municipal" class="col-form-label">Número</label>
                            <div class="col">
                                <input type="text" class="form-control text-left" placeholder="Digite"
                                    id="insc_municipal_id" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5 mt-10">
                        <div class="form-group">
                            <label for="insc_municipal" class="col-form-label">Série</label>
                            <div class="col">
                                <input type="text" class="form-control text-left" placeholder="Digite"
                                    id="insc_municipal_id" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1 mt-10">
                        <div class="form-group">
                            <label for="insc_municipal" class="col-form-label">&nbsp;</label>
                            <div class="col ">
                                <span class="nada mt-50">OU</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5 mt-10">
                        <div class="form-group">
                            <label for="insc_municipal" class="col-form-label">Chave de Acesso</label>
                            <div class="col">
                                <input type="text" class="form-control text-left" placeholder="Digite"
                                    id="insc_municipal_id" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="card-body d-flex justify-content-center align-items-center" id="top">
                        <div class="col-md-12 mt-12 text-center"> <!-- Adicionado "text-center" aqui -->
                            <button class="btn btn-secondary" @click="back()">
                                Adicionar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>
<style></style>