<script>
import appConfig from "@/app.config";
import { http } from "@/helpers/easyindustriaapi/config";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
import insertpedido from "./alterar-pedido";
import { listCFOPs } from "@/components/defaults/cfops";
//  import Buefy from 'buefy'
//  import { Tooltip} from 'buefy'
//  import 'buefy/dist/buefy.css' // perguntar a paulo;
// pq qunado tira da certo , mais fica bugado os botões;
//Vue.use(Input)
import Vue from "vue";
import VueDirectiveTooltip from "vue-directive-tooltip";
import docRef from "./doc_referenciado.vue";
import autorizada from './autorizada_baixa.vue'
import contribuinte from './contribuite.vue'
import referenciado from './referenciado.vue'
import info_compra from './info_compras.vue'
import exportacao from './exportacao.vue'
import Widget from './widget.vue'

//import 'vue-directive-tooltip/css/index.css'

Vue.use(VueDirectiveTooltip, {
  delay: 0,
});

//Vue.use(Tooltip)

Vue.directive("percentage", {
  bind(el, binding) {
    el.value = `${binding.value}%`;
  },
  update(el, binding) {
    el.value = `${binding.value}%`;
  },
});

export default {
  page: {
    title: "Produto Vendas",
    meta: [{ name: "description", content: appConfig.description }],
  },
  props: {
    //currentEmpresa: { type: Object },
    //newPedido: { type: Object },
    // listTabelas: { type: Object },
    list_prod: { type: Array },
    p1: { type: Array },
    // currentEmpresa: { type: Object },
    historicos: { type: Array },
    movimentos: { type: Array },
    estoques: { type: Array },
    caixas: { type: Array },
    ct_custos: { type: Array },
    ct_resultados: { type: Array },
    pagamentos: { type: Array },
    empresa_current: { type: Object },
    currentUser: { type: Object }
    //currentUser: { type: Object }
    // compara  a uf da empresa com a uf do cliente;
    // empresa // para comparar com com a uf do clinete
  },
  components: {
    Multiselect,
    insertpedido,
    docRef,
    autorizada,
    contribuinte,
    referenciado,
    info_compra,
    exportacao,
    Widget
    //  Toast
  },
  data() {
    return {
      variants: [
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
      currentPedido: {},
      titleBody: "Produto Vendas",
      submitted: false,
      pedido: {},
      listCFOP1: [],
      errors: {},
      listUnidades: ["UND", "MT", "FARD", "M2", "M3", "PCT"],
      items1: [
        {
          id: 1,
          descricao: "",
          unidade: "",
          CFOP: "",
          Quantidade: 1,
          Unitario: "",
          Bruto: "",
          Desconto: "",
        },
      ],
      percentageValue: 0,
      pedido_receb: [],
      res_sub: undefined,
      codBBar: null,
      selecionado: null, // converte de array para variavel;
      quantidade: null,
      preco: null,
      subTotal: null,
      desconto: null,
      Porcentagem: null,
      img: false,
      inputValue: null,
      Lista_Produtos: [],
      searchString: "",
      produtos: [

      ],
      // produtos: [
      //   { id: 1, nome: "Camiseta", preco: 29.9, CFOP: "1234" },
      //   { id: 2, nome: "Calça Jeans", preco: 89.9, CFOP: "1334" },
      //   { id: 3, nome: "Tênis", preco: 129.9, CFOP: "1244" },
      //   {
      //     id: 4,
      //     nome: "Jaqueta de Couro",
      //     preco: 399.9,
      //     CFOP: "12345",
      //   },
      //   { id: 5, nome: "Bermuda", preco: 39.9, CFOP: "1224" },
      //   { id: 6, nome: "Sapato Social", preco: 189.9, CFOP: "1214" },
      // ],
      produtosSelecionados: [],
      busca: "",
      item_descri: [
        {
          descric1: '',
          cfop2: ''
        }
      ],
      series: [
        '1',
        '2',
        '3',
        '4',
      ],

      // criei essas duas variaveis pq quando coloca o proprio item da erro;
      // fica em branco apesar de pegar o valor correto; 
      descricao2: null,
      cfop1: null,
      // produtoSelecionado: { nome: "", preco: 0 },
      // quanod adicionar um documento referneciado esse vetor é preenchido 
      // e consequentemente a tabela é preenchida;
      // esse vetor tem que um vetor de objeto ;
      // esta sendo utilizado pelo b-table  
      doc_referenciados: [

      ],
      centro_custo_vet: [
        {
          id: 1,
          cod_c_custo: 1,
          ctc: 1,
          cod_res: 1,
          cent_result: 1,
          valor: 1,
          ativo: 1,
        }
      ],
      id: 1,
      cod_c_custo: 1,
      centrocusto_id: 1,
      cod_res: 1,
      centroresultado_id: 1,
      valor: 1,
      ativo: 1,
      verifica_doc: false,
      fields: [
        { label: "Código", key: "id", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Código C Custo", key: "cod_c_custo", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Centro Custo", key: "ctc", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Código Centro Resultado", key: "cod_res", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Centro Resultado", key: "cent_result", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Valor", key: "valor", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Ativo", key: "ativo", tdClass: 'text-center', thClass: 'text-center' },
        // { label: "Número", key: "numero", tdClass: 'text-center', thClass: 'text-center' },
        // { label: "Série", key: "serie", tdClass: 'text-center', thClass: 'text-center', formatter: "formatterCurrBR" },
        // { label: "Criado", key: "data_criacao", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Ações", key: "excluir", tdClass: 'text-center', thClass: 'text-center' },
      ],
      faturas: [
        {
          num_fatura: 'nada',
          valor: 1,
          desconto: 3,
          liquido: 3
        }
      ]
      ,
      fields2: [
        { label: "Número da Fatura", key: "num_fatura", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Valor Original", key: "valor", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Valor do Desconto", key: "desconto", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Valor Líquido", key: "liquido", tdClass: 'text-center', thClass: 'text-center', formatter: "formatterCurrBR" },
        // { label: "Criado", key: "data_criacao", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Ações", key: "excluir", tdClass: 'text-center', thClass: 'text-center' },
      ],
      // criar um veotr para usar com esse fields;
      fields3: [
        { label: "Parcela", key: "parcela", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Venciemnto", key: "vencimento", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Valor", key: "valor", tdClass: 'text-center', thClass: 'text-center' },
        // { label: "Criado", key: "data_criacao", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Ações", key: "excluir", tdClass: 'text-center', thClass: 'text-center' },
      ],
      fields6: [
        { label: "Vollacreid", key: "parcela", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Volume transp Id", key: "vencimento", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Item", key: "valor", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Número do Lacre", key: "valor1", tdClass: 'text-center', thClass: 'text-center' },
        // { label: "Número Vol", key: "valor3", tdClass: 'text-center', thClass: 'text-center' },
        // { label: "Peso Liquido", key: "valor4", tdClass: 'text-center', thClass: 'text-center' },
        // { label: "Peso Brutoo", key: "valor5", tdClass: 'text-center', thClass: 'text-center' },
        // { label: "Criado", key: "data_criacao", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Ações", key: "excluir", tdClass: 'text-center', thClass: 'text-center' },
      ],
      parcelas: [
        {
          parcela: 1,
          vencimento: '12',
          valor: 1
        }
      ],
      vari: true,
      empresa: '',
      serie: '',
      numero: '',
      natureza: '',
      emissao: '',
      saida: '',
      finalidade: '',
      nfe: '',
      destino: '',
      presenca: '',
      operacao: '',
      autorizada: false,
      adicionais: [
      ],
      condicao: true,
      contribuinte: false,
      referenciado: false,
      info_compra: false,
      exportacao: false,
      dados_pedido: {
        caixa: 'padrão',
        serie: '',
        numero: 1,
        historicopdr_id: null,
        dataemissao: null,
        saida: null,
        finalidade: null,
        tipo_nfe: null,
        tp_atendimento: null,
        chave: null,
        natureza: 'Venda',
        cons_final: null,
        tpmovimento_id: null,
        modelo: '55',
        cfop: null,
        local_estoque: null,
        cnpj_intermed: null,
        identificação: null,
        tp_itermediador: null,
        tpnf: null,
      },
      historicopdr_id: null,
      fatura_vet: [

      ],
      fields_fatura: [
        { label: "id_pag", key: "id_pag", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Número de Paracelas", key: "n_parcelas", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Quantidade de Dias", key: "qtd_dias", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Vencimento", key: "vencimento", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Tipo Pagemento", key: "tp_pagamento", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Banco", key: "banco", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Valor", key: "valor", tdClass: 'text-center', thClass: 'text-center' },
        // { label: "Criado", key: "data_criacao", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Ações", key: "excluir", tdClass: 'text-center', thClass: 'text-center' },
        // { label: "Ações", key: "excluir2", tdClass: 'text-center', thClass: 'text-center' },
      ],
      fields_fatura_input: [
        { label: "id_pag", key: "id_pag", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Número de Paracelas", key: "n_parcelas", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Quantidade de Dias", key: "qtd_dias", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Vencimento", key: "vencimento", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Tipo Pagemento", key: "tp_pagamento", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Banco", key: "banco", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Valor", key: "valor", tdClass: 'text-center', thClass: 'text-center' },
        // { label: "Criado", key: "data_criacao", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Ações", key: "excluir", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Ações", key: "excluir2", tdClass: 'text-center', thClass: 'text-center' },
      ],
      fatura: {
        num_fatura: 1,
        qtde_parcelas: 2,
        qtd_dias: 3,
        dt_parcela: '',
        tipo_pagamento_id: ''
      },
      cliente_fornecedor: {
        codigo: null,
        nome: null,
      },
      totais: {
        vprod: null,
        vl_servicos: null,
        vdesc: null,
        vl_icms: null,
        vnf: null,
      },
      ref_vet: [
      ],
      item_ref: '',
      id_cupom: '',
      chave_acesso: '',
      fields_ref: [
        { label: "item", key: "item_ref", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Cupom ID", key: "id_cupom", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Chave de Acesso", key: "chave_acesso", tdClass: 'text-center', thClass: 'text-center' },
        // { label: "Vencimento", key: "vencimento", tdClass: 'text-center', thClass: 'text-center' },
        // { label: "Tipo Pagemento", key: "tp_pagamento", tdClass: 'text-center', thClass: 'text-center' },
        // { label: "Banco", key: "banco", tdClass: 'text-center', thClass: 'text-center' },
        // { label: "Valor", key: "valor", tdClass: 'text-center', thClass: 'text-center' },
        // { label: "Criado", key: "data_criacao", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Ações", key: "excluir", tdClass: 'text-center', thClass: 'text-center' },
      ],
      meio_transporte: [

      ],
      UFS: [
        'PE',
        'BA',
        'CE',
      ],
      placas: [
        'ffbm111',
        'GGmno12',
        'kkls333'
      ],
      modalidade_transportes: [
        '0-Por Conta do Emitente',
        '1-Por Conta do Destinatario/Remetente',
        '2-Por Conta de Terceiros',
        '3-Por Conta de Terceiros'
      ],
      modfrete: null,
      transp_xnome: null,
      transp_ie: null,
      transp_xender: null,
      transp_xmun: null,
      transp_uf: null,
      transp_vserv: null,
      transp_cfop: null,
      transp_cmunfg: null,
      transp_balsa: null,
      transp_vagao: null,
      transp_placa: null,
      transp_rntc: null,
      volqvol: null,
      volesp: null,
      volmarca: null,
      volpesol: null,
      volpesob: null,
      placa: "JKL333",
      uf: 'PE',
      rntc: 1,
      fields_transporte: [
        { label: "Placa", key: "placa", tdClass: 'text-center', thClass: 'text-center' },
        { label: "UF", key: "uf", tdClass: 'text-center', thClass: 'text-center' },
        { label: "RNTC", key: "rntc", tdClass: 'text-center', thClass: 'text-center' },
        // { label: "Vencimento", key: "vencimento", tdClass: 'text-center', thClass: 'text-center' },
        // { label: "Tipo Pagemento", key: "tp_pagamento", tdClass: 'text-center', thClass: 'text-center' },
        // { label: "Banco", key: "banco", tdClass: 'text-center', thClass: 'text-center' },
        // { label: "Valor", key: "valor", tdClass: 'text-center', thClass: 'text-center' },
        // { label: "Criado", key: "data_criacao", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Ações", key: "excluir", tdClass: 'text-center', thClass: 'text-center' },
      ],
      fields4: [
        { label: "item", key: "item", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Quantidade Volume", key: "Volume", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Especie", key: "especie", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Marca", key: "marca", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Número Vol", key: "num_vol", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Peso Liquido", key: "peso_liq", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Peso Bruto", key: "peso_bt", tdClass: 'text-center', thClass: 'text-center' },
        // { label: "Criado", key: "data_criacao", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Ações", key: "excluir", tdClass: 'text-center', thClass: 'text-center' },
      ],
      volumes: [
      ],
      item: 1,
      Volume: 1,
      especie: 'nada',
      marca: 1,
      num_vol: 123,
      peso_liq: 132,
      peso_bt: 34,
      fields5: [
        { label: "Vollacreid", key: "volcredid", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Volume transp Id", key: "vol_transp_id", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Item", key: "item_lacre", tdClass: 'text-center', thClass: 'text-center' },
        { label: "Número do Lacre", key: "num_lacre", tdClass: 'text-center', thClass: 'text-center' },
        // { label: "Número Vol", key: "valor3", tdClass: 'text-center', thClass: 'text-center' },
        // { label: "Peso Liquido", key: "valor4", tdClass: 'text-center', thClass: 'text-center' },
        // { label: "Peso Brutoo", key: "valor5", tdClass: 'text-center', thClass: 'text-center' },
        // { label: "Criado", key: "data_criacao", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Ações", key: "excluir", tdClass: 'text-center', thClass: 'text-center' },
      ],
      lacres: [

      ],
      volcredid: 1,
      vol_transp_id: 1,
      item_lacre: 1,
      num_lacre: 1,
      historicos_padr: null,
      finalidades: [
        {
          id: 1,
          nome: 'NF-e Normal'
        },
        {
          id: 2,
          nome: 'NF-e Complementar'
        },
        {
          id: 3,
          nome: 'NF-e de Ajuste'
        },
        {
          id: 4,
          nome: 'Devolucão/Retorno'
        }
      ],
      consumidores: [
        {
          id: 1,
          nome: 'sim'
        },
        {
          id: 2,
          nome: 'não'
        },
      ],
      atentimentos: [
        {
          id: 1,
          nome: 'não se aplica'
        },
        {
          id: 2,
          nome: 'Operação Presencial'
        },
        {
          id: 3,
          nome: 'Operação Não Presencial - Pela internet'
        },
        {
          id: 4,
          nome: 'Operação Não Presencial - tele atendiemtno'
        },
        {
          id: 5,
          nome: 'NFC-e em Operação com entregaa domicilio'
        },
        {
          id: 6,
          nome: 'Operação Presencial - Fora do estabelecimento'
        },
        {
          id: 7,
          nome: 'Operação Não Presencial - Outros'
        },
      ],
      tp_movimentos: [

      ],
      local_estoques: [

      ],
      bs_calc: null,
      alqt_retencao: null,
      vl_servico: null,
      vl_icms_ret: null,
      cidades_ibge1: [],
      estados: [],
      est: null,
      cidad: null,
      caixas1: [
      ],
      select_caixa: null,
      tpmovimento_id: null,
      pessoas: [],
      centro_custos: [],
      centro_resultados: [],
      veri_intermed: true,
      // Para Aba de renteçõa ICMS 
      ret_cfop: null,
      fat_vlr: null,
      pagamentos_tp: null,
      nada: null,
      veri_credito: false,
      veri_cheque: false,
      veri_debito: false,
      verifica_fatura: false,
      veribandeira: false,
      modal_credito: false,
      modal_debito: false,
      modal_cheque: false,
      modal_cliente: false,
      credito: {
        ordem: '',
        bandeira: ''
      },
      debito: {
        ordem: '',
        bandeira: ''
      },
      cheque: {
        n_cheque: '',
        emitente: '',
        cnpj: '',
        ag: '',
        ncc: '',
        banco: '',
        valor: ''
      },
      pessoas_vazio: [],
      paises: [],
      paises_nome: [],
      indicadores_IE: [
        {
          id: 1,
          nome: 'Contribuinte ICMS'
        },
        {
          id: 2,
          nome: 'Contribuinte Isento'
        },
        {
          id: 3,
          nome: 'Não Contribuinte'
        },
      ],
      empresa2: null,
      cfop_certt: null,
      empresa_atual: null,
      usuario_atual: null,
      configs_empresa: null,
      tem_desconto:false,
      produto_atual: null,
    };
  },
  validations() {
    return {
      pedido: {
        // tpnf: { required },
        nnf: { required },
      },
    };
  },
  computed: {
    produtosFiltrados() {
      return this.produtos.filter((produto) =>
        produto.produto_nome.toLowerCase().includes(this.busca.toLowerCase())
      );
    },
    verifica_tipos() {
      if (this.veri_credito == false && this.veri_cheque == false && this.veri_debito == false) {
        return false
      }
      return true;
    }
    // pessoasFiltradas() {
    //   return this.pessoas.filter((pessoa) =>
    //     pessoa.nome.toLFowerCase().includes(this.busca.toLowerCase())
    //   );
    // },
  },
  // watch: {
  //   produtosSelecionados: function () {
  //     this.produtoSelecionado = this.produtosSelecionados[0] || {
  //       nome: "",
  //       preco: 0,
  //     };
  //   },
  //   pessoasSelecionadas: function () {
  //     this.pessoaSelecionada = this.pessoasSelecionadas[0] || {
  //       nome: "",
  //       idade: "",
  //     };
  //   },
  // },
  created() {
    this.historicos_padr = this.historicos
    this.tp_movimentos = this.movimentos
    this.local_estoques = this.estoques
    this.centro_custos = this.ct_custos
    this.centro_resultados = this.ct_resultados
    this.caixas1 = this.caixas
    // esta dando erro aqui pois não tinha nehum caixa aberto;

    this.pagamentos_tp = this.pagamentos
    // this.empresa2 = this.currentEmpresa
    this.dados_pedido.tpnf = "2"
    // this.usuario_atual = this.currentUser
    this.empresa_atual = this.empresa_current
    this.usuario_atual = this.currentUser
    this.pessoas = this.p1
  },
  mounted() {
    this.setNewTitle();
    //this.pedido = this.newPedido;
    this.getData_produto();
    this.listCFOP1 = listCFOPs;
    // console.log(this.listCFOP)
    this.filtar_cfops()
    //this.cidades_ibge()
    this.estados_ibge()

    this.historicopdr_id = this.historicos[1]
    this.tpmovimento_id = this.tp_movimentos[0]
    this.dados_pedido.serie = this.tp_movimentos[0].serie
    this.dados_pedido.finalidade = this.finalidades[0]
    this.dados_pedido.cons_final = this.consumidores[1]
    // this.this.tp_movimentos =this.tp_movimentos
    this.dados_pedido.cfop = "5.102"
    //console.log(this.tpmovimento_id)
    this.get_paises()
    // this.pagamentos_tp = this.pagamentos
    //this.nome_paises()
    // this.select_tp_mov_preench()
    //console.log(this.empresa2)
    // console.log(this.usuario_atual)
    console.log(this.empresa_atual)
    this.empresas_config()
    this.verifica_caixa()
  },
  methods: {
    verifica_caixa() {
      // this.select_caixa = this.caixas[0].id
      if (this.caixas.length === 0) {
        this.select_caixa = 'Vazio'
      } else {
        this.select_caixa = this.caixas[0].id
      }
    },
    adicionarSemDuplicatas(novoObjeto) {
      if (!this.estados.some(objeto => objeto.nome === novoObjeto.nome)) {
        this.estados.push(novoObjeto);
      }
    },
    convert_citys() {
      this.cidades_ibge1.forEach((cidade) => {
        this.adicionarSemDuplicatas(cidade.municipio.microrregiao.mesorregiao.UF);
      });
    },
    select_cidade_por_uf(objeto) {
      console.log(objeto)
      this.cidades_ibge(objeto)
    },
    async cidades_ibge(objeto) {
      let uf = objeto.sigla
      try {
        // bug , aqui , pois não da para pesquisar por emepresa_id 
        // e  só consigo fazer o get por id ou estado ou nome ;   
        let response = await http.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/' + uf + '/municipios');

        if (response.status === 200) {
          this.cidades_ibge1 = response.data;
          //console.log("ibge");
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast('danger', 'Erro 406: ' + (messageErro.tipo) ? messageErro.tiponome : messageErro.tpag);
            //this.offLoader();
            break;
          case 404:
            this.makeToast('danger', 'Erro 404: endpoint não encontrado ou servidor fora do ar');
            ///this.offLoader();
            break;
          default:
            this.makeToast('danger', error.message);
            //this.offLoader();
            break;
        }
      }
    },
    preencher_estoque(configuracao){
      // dados_pedido.local_estoque
      console.log(configuracao.local_estoque_id)
      let estoque_selecionado = this.estoques.find((e) => e.id === configuracao.local_estoque_id)
      this.dados_pedido.local_estoque = estoque_selecionado;
    },
    verifica_empresa_correta(empresa_id) {
      const empresaSelecionada = this.configs_empresa.find((e) => e.id === empresa_id)
      console.log(empresaSelecionada);
      this.preencher_estoque(empresaSelecionada)
      this.dados_pedido.natureza = empresaSelecionada.natureza_operacao
      // natureza_operacao
    },
    async empresas_config() {
      try {
        // bug , aqui , pois não da para pesquisar por emepresa_id 
        // e  só consigo fazer o get por id ou estado ou nome ;   
        let response = await http.get('/configuracao?empresa_id=' + this.empresa_atual.id);
        if (response.status === 200) {
          this.configs_empresa = response.data;
          this.verifica_empresa_correta(this.empresa_atual.id);
          //console.log(this.configs_empresa);
        }
      } catch (error) {
        let messageErro = error.response.data;
        switch (error.response.status) {
          case 406:
            this.makeToast('danger', 'Erro 406: ' + (messageErro.tipo) ? messageErro.tiponome : messageErro.tpag);
            //this.offLoader();
            break;
          case 404:
            this.makeToast('danger', 'Erro 404: endpoint não encontrado ou servidor fora do ar');
            ///this.offLoader();
            break;
          default:
            this.makeToast('danger', error.message);
            //this.offLoader();
            break;
        }
      }
    },
    async estados_ibge() {
      try {
        // bug , aqui , pois não da para pesquisar por emepresa_id 
        // e  só consigo fazer o get por id ou estado ou nome ;   
        let response = await http.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados');

        if (response.status === 200) {
          this.estados = response.data;
          // console.log("ibge");
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast('danger', 'Erro 406: ' + (messageErro.tipo) ? messageErro.tiponome : messageErro.tpag);
            // this.offLoader();
            break;
          case 404:
            this.makeToast('danger', 'Erro 404: endpoint não encontrado ou servidor fora do ar');
            // this.offLoader();
            break;

          default:
            this.makeToast('danger', error.message);
            // this.offLoader();
            break;
        }
      }
    },
    formatInput_valor_cent() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.valor.replace(/\D/g, '');
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.valor = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).replace("R$", " ");
    },
    formatInput_valor_prod_totais() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.totais.vprod.replace(/\D/g, '');
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.totais.vprod = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).replace("R$", " ");
    },
    formatInput_valor_servc_totais() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.totais.vl_servicos.replace(/\D/g, '');
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.totais.vl_servicos = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).replace("R$", " ");
    },
    formatInput_valor_desc_totais() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.totais.vdesc.replace(/\D/g, '');
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.totais.vdesc = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).replace("R$", " ");
    },
    formatInput_valor_imcs_totais() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.totais.vl_icms.replace(/\D/g, '');
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.totais.vl_icms = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).replace("R$", " ");
    },
    formatInput_valor_tot_totais() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.totais.vnf.replace(/\D/g, '');
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.totais.vnf = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).replace("R$", " ");
    },
    formatInput_base_calc() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.bs_calc.replace(/\D/g, '');
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.bs_calc = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).replace("R$", " ");
    },
    formatInput_alqt_ret() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.alqt_retencao.replace(/\D/g, '');
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.alqt_retencao = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).replace("R$", " ");
    },
    formatInput_vl_servc() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.transp_vserv.replace(/\D/g, '');
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.transp_vserv = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).replace("R$", " ");
    },
    formatInput_vl_servc_icms() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.vl_icms_ret.replace(/\D/g, '');
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.vl_icms_ret = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).replace("R$", " ");
    },
    Delete_meio_transporte(e) {
      console.log(e)
      this.meio_transporte.pop();
    },
    add_transporte() {
      this.meio_transporte.push({
        placa: this.placa,
        uf: this.uf,
        rntc: this.rntc
      })
    },
    delete_volume(e) {
      console.log(e)
      this.volumes.pop();
    },
    add_volume() {
      this.volumes.push({
        item: 1,
        Volume: 1,
        especie: 'nada',
        marca: 1,
        num_vol: 123,
        peso_liq: 132,
        peso_bt: 34,
      })
    },
    delete_lacre(e) {
      console.log(e)
      this.lacres.pop();
    },
    add_lacre() {
      this.lacres.push({
        volcredid: this.volcredid,
        vol_transp_id: this.vol_transp_id,
        item_lacre: this.item_lacre,
        num_lacre: this.num_lacre
      })
    },
    add_info_doc() {
      this.ref_vet.push({
        item_ref: this.item_ref,
        id_cupom: this.id_cupom,
        chave_acesso: this.chave_acesso
      })
    },
    delete_info_doc() {
      this.ref_vet.pop()
    },
    gerar_parcelas() {
      // if (this.veri_cheque == true) {
      //   this.fields_fatura = []
      //   this.fields_fatura = [
      //     { label: "id_pag", key: "id_pag", tdClass: 'text-center', thClass: 'text-center' },
      //     { label: "Número de Paracelas", key: "n_parcelas", tdClass: 'text-center', thClass: 'text-center' },
      //     { label: "Quantidade de Dias", key: "qtd_dias", tdClass: 'text-center', thClass: 'text-center' },
      //     { label: "Vencimento", key: "vencimento", tdClass: 'text-center', thClass: 'text-center' },
      //     { label: "Tipo Pagemento", key: "tp_pagamento", tdClass: 'text-center', thClass: 'text-center' },
      //     // { label: "Banco", key: "banco", tdClass: 'text-center', thClass: 'text-center' },
      //     // { label: "Valor", key: "valor", tdClass: 'text-center', thClass: 'text-center' },
      //     { label: "N Cheque", key: "num_cheque1", tdClass: 'text-center', thClass: 'text-center' },
      //     { label: "Emitente", key: "emitente1", tdClass: 'text-center', thClass: 'text-center' },
      //     { label: "Cnpj", key: "cnpj1", tdClass: 'text-center', thClass: 'text-center' },
      //     { label: "AG", key: "ag1", tdClass: 'text-center', thClass: 'text-center' },
      //     { label: "ncc", key: "ncc1", tdClass: 'text-center', thClass: 'text-center' },
      //     { label: "banco", key: "banco", tdClass: 'text-center', thClass: 'text-center' },
      //     { label: "valor", key: "valor", tdClass: 'text-center', thClass: 'text-center' },
      //     // { label: "Criado", key: "data_criacao", tdClass: 'text-right', thClass: 'text-center' },
      //     { label: "Ações", key: "excluir", tdClass: 'text-center', thClass: 'text-center' },
      //   ]

      // } else {
      //   // this.fields_fatura = []
      //   // this.fields_fatura = [
      //   //   { label: "id_pag", key: "id_pag", tdClass: 'text-center', thClass: 'text-center' },
      //   //   { label: "Número de Paracelas", key: "n_parcelas", tdClass: 'text-center', thClass: 'text-center' },
      //   //   { label: "Quantidade de Dias", key: "qtd_dias", tdClass: 'text-center', thClass: 'text-center' },
      //   //   { label: "Vencimento", key: "vencimento", tdClass: 'text-center', thClass: 'text-center' },
      //   //   { label: "Tipo Pagemento", key: "tp_pagamento", tdClass: 'text-center', thClass: 'text-center' },
      //   //   { label: "Banco", key: "banco", tdClass: 'text-center', thClass: 'text-center' },
      //   //   { label: "Valor", key: "valor", tdClass: 'text-center', thClass: 'text-center' },
      //   //   // { label: "Criado", key: "data_criacao", tdClass: 'text-right', thClass: 'text-center' },
      //   //   { label: "Ações", key: "excluir", tdClass: 'text-center', thClass: 'text-center' },
      //   // ]
      // }
      if (this.veri_credito == true) {
        this.fields_fatura = [
        ]
        this.fields_fatura = [
          { label: "id_pag", key: "id_pag", tdClass: 'text-center', thClass: 'text-center' },
          { label: "Número de Paracelas", key: "n_parcelas", tdClass: 'text-center', thClass: 'text-center' },
          { label: "Quantidade de Dias", key: "qtd_dias", tdClass: 'text-center', thClass: 'text-center' },
          { label: "Vencimento", key: "vencimento", tdClass: 'text-center', thClass: 'text-center' },
          { label: "Tipo Pagemento", key: "tp_pagamento", tdClass: 'text-center', thClass: 'text-center' },
          { label: "Banco", key: "banco", tdClass: 'text-center', thClass: 'text-center' },
          { label: "Valor", key: "valor", tdClass: 'text-center', thClass: 'text-center' },
          { label: "Bandeira", key: "bandeira", tdClass: 'text-center', thClass: 'text-center' },
          { label: "Numero de Ordem", key: "num_ordem", tdClass: 'text-center', thClass: 'text-center' },
          // { label: "Criado", key: "data_criacao", tdClass: 'text-right', thClass: 'text-center' },
          { label: "Ações", key: "excluir", tdClass: 'text-center', thClass: 'text-center' },
          // { label: "Ações", key: "excluir2", tdClass: 'text-center', thClass: 'text-center' },
        ]
      } else if (this.veri_debito == true) {
        this.fields_fatura = [
        ]
        this.fields_fatura = [
          { label: "id_pag", key: "id_pag", tdClass: 'text-center', thClass: 'text-center' },
          { label: "Número de Paracelas", key: "n_parcelas", tdClass: 'text-center', thClass: 'text-center' },
          { label: "Quantidade de Dias", key: "qtd_dias", tdClass: 'text-center', thClass: 'text-center' },
          { label: "Vencimento", key: "vencimento", tdClass: 'text-center', thClass: 'text-center' },
          { label: "Tipo Pagemento", key: "tp_pagamento", tdClass: 'text-center', thClass: 'text-center' },
          { label: "Banco", key: "banco", tdClass: 'text-center', thClass: 'text-center' },
          { label: "Valor", key: "valor", tdClass: 'text-center', thClass: 'text-center' },
          { label: "Bandeira", key: "bandeira", tdClass: 'text-center', thClass: 'text-center' },
          { label: "Numero de Ordem", key: "num_ordem", tdClass: 'text-center', thClass: 'text-center' },
          // { label: "Criado", key: "data_criacao", tdClass: 'text-right', thClass: 'text-center' },
          { label: "Ações", key: "excluir", tdClass: 'text-center', thClass: 'text-center' },
          // { label: "Ações", key: "excluir2", tdClass: 'text-center', thClass: 'text-center' },
        ]
      } else if (this.veri_cheque == true) {
        this.fields_fatura = []
        this.fields_fatura = [
          { label: "id_pag", key: "id_pag", tdClass: 'text-center', thClass: 'text-center' },
          { label: "Número de Paracelas", key: "n_parcelas", tdClass: 'text-center', thClass: 'text-center' },
          { label: "Quantidade de Dias", key: "qtd_dias", tdClass: 'text-center', thClass: 'text-center' },
          { label: "Vencimento", key: "vencimento", tdClass: 'text-center', thClass: 'text-center' },
          { label: "Tipo Pagemento", key: "tp_pagamento", tdClass: 'text-center', thClass: 'text-center' },
          // { label: "Banco", key: "banco", tdClass: 'text-center', thClass: 'text-center' },
          // { label: "Valor", key: "valor", tdClass: 'text-center', thClass: 'text-center' },
          { label: "N Cheque", key: "num_cheque", tdClass: 'text-center', thClass: 'text-center' },
          { label: "Emitente", key: "emitente", tdClass: 'text-center', thClass: 'text-center' },
          { label: "Cnpj", key: "cnpj", tdClass: 'text-center', thClass: 'text-center' },
          { label: "AG", key: "ag", tdClass: 'text-center', thClass: 'text-center' },
          { label: "ncc", key: "ncc", tdClass: 'text-center', thClass: 'text-center' },
          { label: "banco", key: "banco", tdClass: 'text-center', thClass: 'text-center' },
          { label: "valor", key: "valor", tdClass: 'text-center', thClass: 'text-center' },
          // { label: "Criado", key: "data_criacao", tdClass: 'text-right', thClass: 'text-center' },
          { label: "Ações", key: "excluir", tdClass: 'text-center', thClass: 'text-center' },
        ]
      }
      // else {
      //   if (this.veri_cheque == false) {
      //     this.fields_fatura = [

      //     ]
      //     this.fields_fatura = [
      //       { label: "id_pag", key: "id_pag", tdClass: 'text-center', thClass: 'text-center' },
      //       { label: "Número de Paracelas", key: "n_parcelas", tdClass: 'text-center', thClass: 'text-center' },
      //       { label: "Quantidade de Dias", key: "qtd_dias", tdClass: 'text-center', thClass: 'text-center' },
      //       { label: "Vencimento", key: "vencimento", tdClass: 'text-center', thClass: 'text-center' },
      //       { label: "Tipo Pagemento", key: "tp_pagamento", tdClass: 'text-center', thClass: 'text-center' },
      //       { label: "Banco", key: "banco", tdClass: 'text-center', thClass: 'text-center' },
      //       { label: "Valor", key: "valor", tdClass: 'text-center', thClass: 'text-center' },
      //       // { label: "Criado", key: "data_criacao", tdClass: 'text-right', thClass: 'text-center' },
      //       { label: "Ações", key: "excluir", tdClass: 'text-center', thClass: 'text-center' },
      //       // { label: "Ações", key: "excluir2", tdClass: 'text-center', thClass: 'text-center' },
      //     ]
      //   }
      // }
      //console.log("gerando Parcelas")
      let data_primeira_parcela = this.fatura.dt_parcela
      //console.log(data_primeira_parcela)
      let valor_fat = this.fat_vlr
      //console.log(valor_fat)
      // this.verifica_fatura
      this.fatura_vet = []
      let valor_float = parseFloat(valor_fat) / this.fatura.qtde_parcelas
      //console.log(valor_fat)
      //console.log(this.fatura.dt_parcela)
     // console.log(valor_float)
      if (this.fatura.dt_parcela == '') {
        this.makeToast("danger", "Data da Parcela Vazia");
      } else if (this.fatura.tipo_pagamento_id == '') {
        this.makeToast("danger", "Insira Um tipo de Pagamento");
      } else if (isNaN(valor_float)) {
        this.makeToast("danger", "Adicione Um produto ");
      }
      else {
        for (let i = 0; i < this.fatura.qtde_parcelas; i++) {
          this.verifica_fatura = true
          //console.log("Este é o passo número " + i);
          const parcela = new Date(data_primeira_parcela);
          parcela.setDate(parcela.getDate() + 30 * i);
          const ano = parcela.getFullYear();
          const mes = parcela.getMonth() + 1; // Lembrando que os meses são zero-based
          const dia = parcela.getDate() + 1;
          // const dataFormatada = `${ano}-${mes.toString().padStart(2, '0')}-${dia.toString().padStart(2, '0')}`;
          const dataFormatada = `${dia.toString().padStart(2, '0')}-${mes.toString().padStart(2, '0')}-${ano}`;
          //console.log(this.fatura.tipo_pagamento_id.id)
          if ((this.fatura.tipo_pagamento_id === '')) {
            console.log("ojeto Vazio")
          }

          if (this.veri_credito === true) {
            this.fatura_vet.push({
              id_pag: 1,
              n_parcelas: 2,
              qtd_dias: 1,
              vencimento: dataFormatada,
              tp_pagamento: this.fatura.tipo_pagamento_id.id,
              banco: 2,
              valor: valor_float,
              bandeira: this.credito.bandeira,
              num_ordem: this.credito.ordem
            })
          } else if (this.veri_debito === true) {
            this.fatura_vet.push({
              id_pag: 1,
              n_parcelas: 2,
              qtd_dias: 1,
              vencimento: dataFormatada,
              tp_pagamento: this.fatura.tipo_pagamento_id.id,
              banco: 2,
              valor: valor_float,
              bandeira: this.debito.bandeira,
              num_ordem: this.debito.ordem
            })
          } else if (this.veri_cheque == true) {
            //   { label: "N Cheque", key: "num_cheque", tdClass: 'text-center', thClass: 'text-center' },
            // { label: "Emitente", key: "emitente", tdClass: 'text-center', thClass: 'text-center' },
            // { label: "Cnpj", key: "cnpj", tdClass: 'text-center', thClass: 'text-center' },
            // { label: "AG", key: "ag", tdClass: 'text-center', thClass: 'text-center' },
            // { label: "ncc", key: "ncc", tdClass: 'text-center', thClass: 'text-center' },
            // { label: "banco", key: "banco", tdClass: 'text-center', thClass: 'text-center' },
            // { label: "valor", key: "valor", tdClass: 'text-center', thClass: 'text-center' },
            this.fatura_vet.push({
              id_pag: 1,
              n_parcelas: 2,
              qtd_dias: 1,
              vencimento: dataFormatada,
              tp_pagamento: this.fatura.tipo_pagamento_id.id,
              banco: this.cheque.banco,
              valor: valor_float,
              num_cheque: this.cheque.n_cheque,
              emitente: this.cheque.emitente,
              cnpj: this.cheque.cnpj,
              ag: this.cheque.ag,
              ncc: this.cheque.ncc,
            })
          } else {
            this.fatura_vet.push({
              id_pag: 1,
              n_parcelas: 2,
              qtd_dias: 1,
              vencimento: dataFormatada,
              tp_pagamento: this.fatura.tipo_pagamento_id.id,
              banco: 2,
              valor: valor_float,
            })
          }

        }
      }
    },
    verificaempresa() {
      if ((this.empresa === '' || this.empresa === null || this.empresa === undefined)) {
        this.vari = true
      } else {
        this.vari = false
      }
      //console.log(this.empresa)
    },
    verificaserie() {
      if ((this.serie === '' || this.serie === null || this.serie === undefined)) {
        this.vari = true
      } else {
        this.vari = false
      }
      //console.log(this.serie)
    },
    verificanumero() {
      if ((this.numero === '' || this.numero === null || this.numero === undefined)) {
        this.vari = true
      } else {
        this.vari = false
      }
    },
    verificanatureza() {
      if ((this.natureza === '' || this.natureza === null || this.natureza === undefined)) {
        this.vari = true
      } else {
        this.vari = false
      }
      //console.log(this.natureza)
    },
    verificaemissao() {
      if ((this.emissao === '' || this.emissao === null || this.emissao === undefined)) {
        this.vari = true
      } else {
        this.vari = false
      }
      //console.log(this.emissao)
    },
    verificasaida() {
      if ((this.saida === '' || this.saida === null || this.saida === undefined)) {
        this.vari = true
      } else {
        this.vari = false
      }
      //console.log(this.saida)
    },
    verificafinalidade() {
      if ((this.finalidade === '' || this.finalidade === null || this.finalidade === undefined)) {
        this.vari = true
      } else {
        this.vari = false
      }
      //console.log(this.finalidade)
    },
    filtar_cfops() {
      let numerosIds = this.listCFOP1.map(objeto => objeto.id);
      this.listCFOP1 = numerosIds
      //   console.log(this.listCFOP);
    },
    atualizarValores(index, produto) {
      // Encontra o produto selecionado pelo seu ID
      const produtoSelecionado = this.produtos.find((p) => p.id === produto.id);
      this.produto_atual = produto
     // console.log(produtoSelecionado.produto_cfop)
      // Atualiza o nome e o preço do produto no item1
      this.items1[index].descricao = produtoSelecionado.produto_nome;
      this.items1[index].Unitario = this.formatterCurrBR(produtoSelecionado.preco);
      this.items1[index].CFOP = produtoSelecionado.produto_cfop;
      //  fiz esse segundo cfop pq estava dando erro ao atribuir o valor no cfop de cima ;
      this.cfop1 = produtoSelecionado.produto_cfop;
      this.item_descri[index].cfop2 = produtoSelecionado.produto_cfop
      // console.log(this.cfop1);
      //  console.log(this.items1[index].CFOP);
      this.items1[index].unidade = produtoSelecionado.um;
      this.items1[index].Desconto = "0";
      this.calcularValorBruto(index)
      // Calcula o valor bruto do item com base na quantidade e no preço unitário do produto
      ///this.items1[index].Bruto = this.valor_Desc(index);
    },
    // comentario para testa se servidor ligou.
    calcularValorBruto(index) {
      // console.log('entrou no valor bruto')
      const item = this.items1[index];
      if (item.Unitario == "" || item.Desconto == "") {
        this.items1[index].Bruto = 0;
      } else if (item.Unitario != "" && item.Desconto == '0,00') {
        this.items1[index].Bruto = item.Bruto
      } else {
        //console.log("mostrando o Desconto")
        let num = item.Desconto
        let num1 = num.replace(/\./g, "")
        // desconto
        let num3 = num1.replace(",", ".")
        // console.log(num1)
        // console.log(num1)
        let num_uni = item.Unitario
        let num_uni_res = num_uni.replace(/\./g, "")
        let num_uni_res2 = num_uni_res.replace(",", ".")
        //console.log(parseFloat(num_uni_res))
        const valorUnitario = parseFloat(num_uni_res2);
        //console.log(valorUnitario)
        const desconto = parseFloat(num3);
        //console.log(desconto)
        const quantidade = parseInt(item.Quantidade);
        const valorBruto = (valorUnitario * quantidade) * (1 - desconto / 100).toFixed(4);
        let valor_bruto2 = this.formatterCurrBR(valorBruto)
        // console.log(valor_bruto2)
        this.items1[index].Bruto = valor_bruto2
        //  console.log(this.items1[index].Bruto)
        this.fatura_valor(index);
      }
    },
    calcularValorBruto2(index) {
      // console.log('entrou no valor bruto')
      const item = this.items1[index];
      if (item.Unitario == "" || item.Desconto == "") {
        this.items1[index].Bruto = 0;
      } else if (item.Unitario != "" && item.Desconto == '0,00') {
        this.items1[index].Bruto = item.Bruto
      } else {
        //console.log("mostrando o Desconto")
        let num = item.Desconto
        let num1 = num.replace(/\./g, "")
        let num3 = num1.replace(",", ".")
        // console.log(num1)
        // console.log(num1)
        let num_uni = item.Unitario
        let num_uni_res = num_uni.replace(/\./g, "")
        let num_uni_res2 = num_uni_res.replace(",", ".")
        //console.log(parseFloat(num_uni_res))
        const valorUnitario = parseFloat(num_uni_res2);
        //console.log(valorUnitario)
        const desconto = parseFloat(num3);
        //console.log(desconto)
        const quantidade = parseInt(item.Quantidade);
        const valorBruto = (valorUnitario * quantidade) - (desconto).toFixed(3);
        let valor_bruto2 = this.formatterCurrBR(valorBruto)
        // console.log(valor_bruto2)
        this.items1[index].Bruto = valor_bruto2
        //  console.log(this.items1[index].Bruto)
        this.fatura_valor(index);
      }
    },

    fatura_valor(index) {
      this.fat_vlr = this.items1[index].Bruto
    },
    customLabel(option) {
      return option.produto_nome;
    },
    selecionarProduto(produto) {
      this.produtoSelecionado = produto;
    },
    removeItem(index) {
      index = index - 1;
      this.items1.splice(index, 1);
      this.item_descri.splice(index, 1);
    },
    soma_dos_itens(){
       var soma = 0
       var soma_desc =0
      //console.log(this.items1.length)
      if(this.items1.length == 1){
        this.totais.vprod = this.items1[0].Bruto
        this.totais.vl_servicos = this.items1[0].Bruto
        this.totais.vnf = this.items1[0].Bruto
        this.totais.vdesc = this.items1[0].Desconto
      }else if(this.items1.length > 1){
        for(var i=0 ; i < this.items1.length; i++){
        let num = this.items1[i].Bruto.replace(',','.')
        soma = soma + parseFloat(num)
        console.log(soma)
        let desc = this.items1[i].Desconto.replace(',','.')
        soma_desc = soma_desc + parseFloat(desc)
      }
      this.totais.vdesc=soma_desc
      this.totais.vl_servicos = this.formatterCurrBR(soma)
      this.totais.vprod = this.formatterCurrBR(soma)
      this.totais.vnf = this.formatterCurrBR(soma)
      }
    },
    addItem() {
      let id1 = this.items1.length + 1;
      // criado para resolver bug de Descrição e CFOOps ,
      // que não estavem sendo salvos na vetor items1 ;
      this.item_descri.push({
        descric1: '',
        cfop2: ''
      })
      let d2 = id1;
      this.items1.push({
        id: d2,
        descricao: "",
        unidade: "",
        CFOP: "",
        Quantidade: 1,
        Unitario: "",
        Bruto: "",
        Desconto: "",
        // nem precisaria desse  , posso usar a descricao;
      });
      this.soma_dos_itens();
    },
    add_c_custo() {
      // let id1 = this.items1.length + 1
      // criado para resolver bug de Descrição e CFOOps ,
      // que não estavem sendo salvos na vetor items1 ;
      this.centro_custo_vet.push({
        id: this.id,
        cod_c_custo: this.cod_c_custo,
        ctc: this.centrocusto_id.identificacao,
        cod_res: this.cod_res,
        cent_result: this.centroresultado_id.identificacao,
        valor: this.valor,
        ativo: this.ativo,
      })

    },
    Delete_cent_custo(objeto) {
      console.log(objeto)
      this.centro_custo_vet.pop();
    },
    addAdicionais() {
      this.condicao = !this.condicao
      let tam = this.adicionais.length;
      // criado para resolver bug de Descrição e CFOOps ,
      // que não estavem sendo salvos na vetor items1 ;
      console.log(tam)
      if (this.condicao || tam == 0) {
        this.adicionais.push({
          textos: '',
        })
      } else {
        this.adicionais.pop()
      }
    },
    setPedido(pedido) {
      this.currentPedido = pedido;
    },
    edit(oldPedido) {
      this.setPedido(oldPedido);
      this.editando = !this.editando;
    },
    async getData_produto() {
      try {
        let response = await http.get(
          "/produto?empresa_id=" + this.empresa_atual.id
        );
        if (response.status === 200) {
          this.Lista_Produtos = response.data; //
          // console.log("lista de produto");
          this.produtos = response.data;
          // console.log(this.produtos);
        } else {
          this.Lista_Produtos = []; // zera o array;
          this.produtos = [];
          this.makeToast("danger", "Erro ao carregar lista");
          //this.offLoader();
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    removeRow() {
      // mão esta sendo usada;
      var tabela = document.getElementById("minhaTabela");
      if (this.cont > 1) {
        this.cont--;
      }
      if (tabela.rows.length > 1) {
        tabela.deleteRow(tabela.rows.length - 1);
      }
    },
    isImageModalActive() {
      this.img = true;
    },
    envio(index) {
      // criado para enviar um objeto para o alterar produto;
      this.pedido_receb = this.items1[index];
      //this.res_sub = this.item1[index]; // resultado do subtotal;
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    setNewTitle() {
      this.$emit("newTitle", this.titleBody);
    },
    getData() {
      this.$emit("onLoader");
      http.get("/pedido/" + this.pedido.id + "/item");
      this.$emit("offLoader");
    },
    selectFornecedor(value) {
      this.pedido.pessoa_id = value ? value.id : null;
    },
    selectTipoPagamento(value) {
      this.pedido.tipopgto_id = value ? value.id : null;
    },
    selectCFOP(value) {
      this.pedido.cfop = value ? value.id : null;
    },
    selectHistoricoPdr(value) {
      this.pedido.historicopdr_id = value ? value.id : null;
    },
    selectTpMovimento(value) {
      this.pedido.tpmovimento_id = value ? value.id : null;
      var cfop = null;
      if (value) {
        if (this.currentEmpresa.uf === this.fornecedorSelecionado.uf) {
          cfop = value.cfop.replace("*", "1").replace(".", "");
        } else {
          cfop = value.cfop.replace("*", "2").replace(".", "");
        }
        this.pedido.serie = value.serie;
        this.pedido.cfop = cfop;
        this.CFOPs.filter((item) => {
          if (item.id === parseInt(cfop)) {
            this.cfopSelecionado = item;
          }
        });
      }
    },
    selectEstoqueOrigem(value) {
      this.pedido.loc_origem_id = value ? value.id : null;
      if (value) {
        this.listLocaisEstoque.filter((item) => {
          if (item.id === value.id) {
            this.estoqueOrigemSelecionado = item;
          }
        });
      }
    },
    selectEstoqueDestino(value) {
      this.pedido.loc_id = value ? value.id : null;
      if (value) {
        this.listLocaisEstoque.filter((item) => {
          if (item.id === value.id) {
            this.estoqueDestinoSelecionado = item;
          }
        });
      }
    },
    // eslint-disable-next-line no-unused-vars
    formPedido(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.pedido.$error) {
        this.makeToast("danger", "Erro de validação!");
      } else {
        this.$emit("doPost", this.pedido);
      }
    },
    abrir_doc_modal() {
      this.verifica_doc = true;
    },
    abrir_autorizada_modal() {
      this.autorizada = true;
    },
    abrir_contribuinte_modal() {
      this.contribuinte = true;
    },
    abrir_referenciado_modal() {
      this.referenciado = true;
    },
    abrir_info_compra_modal() {
      this.info_compra = true;
    },
    abrir_exportacao_modal() {
      this.exportacao = true;
    },
    back() {
      this.verifica_doc = false;
      this.autorizada = false;
      this.contribuinte = false;
      this.referenciado = false;
      this.info_compra = false;
      this.exportacao = false
    },
    Delete_parcela() {
      console.log('deletando parcela')
    },
    selecthist(e) {
      console.log(e)
    },
    selectCliente_cod(e) {
      console.log(e)
      this.cliente_fornecedor.nome = e
    },
    selectCliente_nome(e) {
      this.cliente_fornecedor.codigo = e
    },
    formatInput_valor_unit(index) {
      //console.log(index)
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.items1[index].Unitario.replace(/\D/g, '');
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.items1[index].Unitario = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).replace("R$", " ");
    },
    atrasarAcao() {
      setTimeout(function () {
        console.log("ola")
      }, 3000);

      return 1;
    },
    formatInput_desc(index) {
      console.log(index)
      let Desconto = this.items1[index].Desconto
      if (Desconto.includes('%')) {
        if (isNaN(Desconto = this.items1[index].Desconto)) {
          this.tem_desconto = true;
          setTimeout(function () {
            // O código que você quer executar após o atraso de 3 segundos vai aqui
            let numericValue = this.items1[index].Desconto.replace(/\D/g, '')
            this.items1[index].Desconto = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).replace("R$", " ");
          }, 5000);
          this.calcularValorBruto(index)
        } else {
          this.tem_desconto = false;
          this.calcularValorBruto2(index);
        }
      } else {
        this.tem_desconto = false;
        let numericValue = this.items1[index].Desconto.replace(/\D/g, '');
        this.items1[index].Desconto = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).replace("R$", " ");
        this.calcularValorBruto2(index);
      }
    },
    intermed_veri() {
      // console.log("entour no intermed")
      console.log(this.dados_pedido.tp_itermediador)
      if (this.dados_pedido.tp_itermediador == false) {
        this.veri_intermed = true
        //console.log(this.veri_intermed)
      } else if (this.dados_pedido.tp_itermediador == 1) {
        this.veri_intermed = false
      } else if (this.dados_pedido.tp_itermediador == 2) {
        this.veri_intermed = false
      } else {
        this.veri_intermed = true
      }
    },
    Delete_fatura() {
      this.verifica_fatura = false;
      this.fatura_vet = [];
    }
    ,
    calcularDesconto(index) {
      const valorComPercentual = parseFloat(this.items1[index].Desconto.replace('%', '').replace(',', '.'));
      if (!isNaN(valorComPercentual)) {
        if (this.valorComPercentual.includes('%')) {
          // Converter o valor formatado (com %) de volta para um valor decimal
          const valorDecimal = parseFloat(valorComPercentual / 100);
          this.items1[index].Desconto = (this.items1[index].Unitario * valorDecimal).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).replace("R$", " ");
        } else {
          this.items1[index].Desconto = this.items1[index].Unitario - valorComPercentual;
        }
      } else {
        this.items1[index].Desconto = 0;
      }
    },
    select_tp_pague(e) {
      console.log(e.id)
      if (e.id == 4) {
        this.veri_credito = true;
        this.modal_credito = true;
      } else {
        this.modal_credito = false;
        this.veri_credito = false;
      }
      if (e.id == 5) {
        this.veri_debito = true;
        this.modal_debito = true;
      } else {
        this.modal_debito = false;
        this.veri_debito = false;
      }
      if (e.id == 3) {
        this.veri_cheque = true
        this.modal_cheque = true
      } else {
        this.veri_cheque = false
        this.modal_cheque = false
      }
    },
    back_modal_credito() {
      this.modal_credito = false;
      this.makeToast('success', 'Informações Salvas')
    },
    back_modal_debito() {
      this.modal_debito = false
      this.makeToast('success', 'Informações Salvas')
    },
    back_modal_cheque() {
      this.modal_cheque = false
      this.makeToast('success', 'Informações Salvas')
    },
    back_modal_cliente() {
      this.modal_cliente = false
      this.makeToast('success', 'Informações Salvas')
    },
    abrir_modal_cliente() {
      this.modal_cliente = true
    },
    customLabelHistorico({ id, descricao }) {
      return `${id} – ${descricao}`
    },
    customLabelCentro_custo({ id, identificacao }) {
      return `${id} – ${identificacao}`
    },
    customLabelCentro_resultado({ id, identificacao }) {
      return `${id} – ${identificacao}`
    },
    customLabelCentro_tipo_pagamento({ id, tiponome }) {
      return `${id} – ${tiponome}`
    },
    customLabel_identificador_vendedor({ id, pes_fantasia }) {
      return `${id} – ${pes_fantasia}`
    },
    select_tp_mov_preench(e) {
      // compara se é entra ou saida 
      // compara o uf da emrpesa com o do cliente;
      // se for igual é 1 , fora do estaod 2, exterior 3
      // console.log(this.empresa2.uf) // empresa 
      // console.log(this.pessoas[0].pes_uf) // cliente
      // console.log(e);  // verifica entreda e saida;
      //console.log(e.es)

      ///console.log(this.pessoas[0])
      this.dados_pedido.serie = e.serie
      var cfop_certo // cfop sem o *
      if (e.es == 0) {
        this.dados_pedido.tpnf = "1"
        // console.log('nada');
        if (this.empresa_atual.uf == this.pessoas[0].pes_uf) {
          //   console.log(e.cfop)
          cfop_certo = e.cfop.replace("*", "1")
          //console.log(cfop_certo)
          this.cfop_certt = cfop_certo
          this.dados_pedido.cfop = cfop_certo
        } else if (this.empresa_atual.uf != this.pessoas[0].pes_uf) {
          // fazer um foreach para verificar com todos os outro estados;
          // se não for igual a nenhuma estaod é 3
          cfop_certo = e.cfop.replace("*", "2")
          this.cfop_certt = cfop_certo
          this.dados_pedido.cfop = cfop_certo
        } else if (this.empresa_atual.uf == 'ex') {
          cfop_certo = e.cfop.replace("*", "3")
          this.cfop_certt = cfop_certo
          this.dados_pedido.cfop = cfop_certo
        }
      } else if (e.es == 1) {
        this.dados_pedido.tpnf = "2"
        if (this.empresa_atual.uf == this.pessoas[0].pes_uf) {
          //  console.log(e.cfop)
          cfop_certo = e.cfop.replace("*", "5")
          //console.log(cfop_certo)
          this.cfop_certt = cfop_certo
          this.dados_pedido.cfop = cfop_certo
        } else if (this.empresa_atual.uf != this.pessoas[0].pes_uf) {
          // fazer um foreach para verificar com todos os outro estados;
          // se não for igual a nenhuma estaod é 7
          cfop_certo = e.cfop.replace("*", "6")
          this.cfop_certt = cfop_certo
          this.dados_pedido.cfop = cfop_certo
        } else if (this.empresa_atual.uf == 'ex') {
          cfop_certo = e.cfop.replace("*", "7")
          this.cfop_certt = cfop_certo
          this.dados_pedido.cfop = cfop_certo
        }
      }
    },
    select_tp_mov_cliente(c) {
      const e = this.tpmovimento_id
      this.dados_pedido.serie = e.serie
      var cfop_certo // cfop sem o *
      if (e.es == 0) {
        this.dados_pedido.tpnf = "1"
        // console.log('nada');
        if (this.empresa_atual.uf == c.pes_uf) {
          //   console.log(e.cfop)
          cfop_certo = e.cfop.replace("*", "1")
          //console.log(cfop_certo)
          this.cfop_certt = cfop_certo
          this.dados_pedido.cfop = cfop_certo
        } else if (this.empresa_atual.uf != c.pes_uf) {
          // fazer um foreach para verificar com todos os outro estados;
          // se não for igual a nenhuma estaod é 3
          cfop_certo = e.cfop.replace("*", "2")
          this.cfop_certt = cfop_certo
          this.dados_pedido.cfop = cfop_certo
        } else if (this.empresa_atual.uf == 'ex') {
          cfop_certo = e.cfop.replace("*", "3")
          this.cfop_certt = cfop_certo
          this.dados_pedido.cfop = cfop_certo
        }
      } else if (e.es == 1) {
        this.dados_pedido.tpnf = "2"
        if (this.empresa_atual.uf == c.pes_uf) {
          //  console.log(e.cfop)
          cfop_certo = e.cfop.replace("*", "5")
          //console.log(cfop_certo)
          this.cfop_certt = cfop_certo
          this.dados_pedido.cfop = cfop_certo
        } else if (this.empresa_atual.uf != c.pes_uf) {
          // fazer um foreach para verificar com todos os outro estados;
          // se não for igual a nenhuma estaod é 7
          cfop_certo = e.cfop.replace("*", "6")
          this.cfop_certt = cfop_certo
          this.dados_pedido.cfop = cfop_certo
        } else if (this.empresa_atual.uf == 'ex') {
          cfop_certo = e.cfop.replace("*", "7")
          this.cfop_certt = cfop_certo
          this.dados_pedido.cfop = cfop_certo
        }
      }
    },
    async get_paises() {
      //console.log("paises");
      try {
        // await http.get("https://servicodados.ibge.gov.br/api/v1/paises/AF|ZA|AL|DE|AD|AO|AI|AQ|AG|SA|DZ|AR|AM|AW|AU|AT|AZ|BS|BH|BD|BB|BY|BE|BZ|BJ|BM|BO|BA|BW|BR|BN|BG|BF|BI|BT|CV|CM|KH|CA|KZ|TD|CL|CN|CY|VA|SG|CO|KM|KP|KR|CI|CR|HR|CU|CW|DK|DJ|DM|EG|SV|AE|EC|ER|SK|SI|ES|FM|US|EE|SZ|ET|FJ|PH|FI|FR|GA|GM|GH|GE|GS|GI|GR|GD|GL|GP|GU|GT|GG|GY|GF|GN|GQ|GW|HT|HN|HK|HU|YE|BV|RE|IM|CX|NF|AX|KY|CC|CK|FO|FK|MP|MH|UM|PN|SB|TC|VG|VI|IN|ID|IR|IQ|IE|IS|IL|IT|JM|JP|JE|JO|KI|XK|KW|LA|LS|LV|LB|LR|LY|LI|LT|LU|MO|MG|MY|MW|MV|ML|MT|MA|MQ|MU|MR|YT|MX|MM|MZ|MD|MN|ME|MS|NA|NR|NP|NI|NE|NG|NU|MK|NO|NC|NZ|OM|HM|NL|BQ|PW|PS|PA|PG|PK|PY|PE|PF|PL|PR|PT|MC|QA|KE|KG|TZ|CZ|IO|TF|TL|TG|TK|TO|TT|TN|TM|TR|TV|UG|UY|UZ|VU|VE|VN|WF|ZM|ZW")

        // await http.get("https://servicodados.ibge.gov.br/api/v1/paises/BR")
        // await http.get("https://servicodados.ibge.gov.br/api/v1/localidades/paises")
        // let countryCode ='US'
        // let username = 'DEMO'
        // await http.get(`http://api.geonames.org/childrenJSON?geonameId=${countryCode}&username=${username}`)
        await http.get("https://servicodados.ibge.gov.br/api/v1/localidades/paises")
          .then(res => {
            this.paises = res.data ? res.data : null
            // console.log(this.paises)
            this.nome_paises();
          })
      } catch (error) {
        // this.offLoader();
        if (error.response && error.response.status === 403) {
          this.error('Usuário não tem permissão!');
        }
      }
    },

    nome_paises() {
      this.paises.forEach((pais) => {
        this.paises_nome.push(pais.nome)
      });
      // console.log(this.paises_nome)
    },
    pais_selecionado(e) {
      console.log(e)
    }
  },
};
</script>
<template>
  <div class="card">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <Widget></Widget>
          </div>
        </div>>
      </div>
    </div>
    <b-card-header header-tag="header" class="p-1 " role="tab">
      <b-button :class="{ 'green-header': vari === false }" block v-b-toggle.emitente variant="light">Dados do Pedido
      </b-button>
    </b-card-header>
    <!-- visible - para deixar aberto é so colocar esse comando no colapse -->
    <b-collapse id="emitente" accordion="pedido-accordion" role="tabpanel">
      <b-card-body>
        <div class="row">
          <div class="col-sm-6 col-md-3">
            <div class="form-group">
              <label for="insc_municipal" class="col-form-label">Caixa</label>
              <div class="col">
                <!-- <multiselect v-model="select_caixa" :options="caixas1" label="id">

                </multiselect> -->
                <input disabled type="text" class="form-control text-left" v-model="select_caixa">
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3">
            <div class="form-group">
              <label for="insc_municipal" class="col-form-label">Série</label>
              <div class="col">
                <input disabled v-model="dados_pedido.serie" type="text" class="form-control text-right"
                  placeholder="digite a serie" id="insc_municipal_id" />
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3">
            <div class="form-group">
              <label for="insc_municipal" class="col-form-label">Número</label>
              <div class="col">
                <input value="1" disabled v-model="dados_pedido.numero" @input="verificanumero(), verificanatureza()"
                  type="text" class="form-control text-right" placeholder="Digite o Número" id="insc_municipal_id" />
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3">
            <div class="form-group">
              <label for="insc_municipal" class="col-form-label">Historico Padrão</label>
              <div class="col">
                <!-- :custom-label="customLabelHistorico" -->
                <multiselect v-model="historicopdr_id" placeholder="Selecione" label="descricao" track-by="descricao"
                  :options="historicos_padr" :option-height="104" :custom-label="customLabelHistorico"
                  :show-labels="false" deselect-label="ENTER para remover" select-label="Pressione ENTER"
                  @select="selecthist($event)">
                  <template slot="singleLabel" slot-scope="props">
                    <span class="option__desc">
                      <span class="option__title">{{ props.option.id }} - {{
                        props.option.descricao }}</span>
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title">{{ props.option.id }} - {{
                        props.option.descricao }}</span>
                    </div>
                  </template><span slot="noResult">Opa! Nenhum registro encontrado</span>
                </multiselect>
                <!-- <multiselect :options="historicos_padr" label="descricao" v-model="historicopdr_id"
                  @select="selecthist($event)">

                </multiselect> -->
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 col-md-4">
            <div class="form-group">
              <label for="insc_municipal" class="col-form-label">Data/Hora emissão</label>
              <div class="col">
                <input v-model="dados_pedido.dataemissao" @input="verificaemissao(), verificasaida()"
                  type="datetime-local" class="form-control text-right" placeholder="seleciona a data da emissão"
                  id="insc_municipal_id" />
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-4">
            <div class="form-group">
              <label for="insc_municipal" class="col-form-label">Data/Hora Saida</label>
              <div class="col">
                <input v-model="dados_pedido.saida" @input="verificasaida(), verificafinalidade()" type="datetime-local"
                  class="form-control text-right" placeholder="seleciona a data da saida" id="insc_municipal_id" />
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-4">
            <div class="form-group">
              <label for="insc_municipal" class="col-form-label">Finalidade NF-e</label>
              <div class="col">
                <multiselect :options="finalidades" label="nome" v-model="dados_pedido.finalidade"
                  @input="verificafinalidade()" @select="verificafinalidade()">

                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 col-md-4">
            <div class="form-group">
              <label for="insc_municipal" class="col-form-label">Tipo de Atendimento</label>
              <div class="col">
                <multiselect v-model="dados_pedido.tp_atendimento" :options="atentimentos" label="nome">
                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-4">
            <div class="form-group">
              <label for="insc_municipal" class="col-form-label">Natureza Da Operação</label>
              <div class="col">
                <input v-model="dados_pedido.natureza" type="text" class="form-control text-left" placeholder="digite "
                  id="natureza_id" />
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-4">
            <div class="form-group">
              <label for="insc_municipal" class="col-form-label">Consumidor Final</label>
              <div class="col">
                <multiselect v-model="dados_pedido.cons_final" :options="consumidores" label="nome">

                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-4">
            <div class="form-group">
              <label for="insc_municipal" class="col-form-label">Tipo de Movimento</label>
              <div class="col">
                <multiselect v-model="tpmovimento_id" label="nometpmov" :options="tp_movimentos"
                  @select="select_tp_mov_preench($event)">

                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-4">
            <div class="form-group">
              <label for="insc_municipal" class="col-form-label">Modelo</label>
              <div class="col">
                <input v-model="dados_pedido.modelo" type="text" class="form-control text-left"
                  placeholder="digite Modelo" id="insc_municipal_id" />
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-4">
            <div class="form-group">
              <!-- {{ cfop_certt }} -->
              <label for="insc_municipal" class="col-form-label">CFOP</label>
              <div class="col">
                <multiselect v-model="dados_pedido.cfop" :options="listCFOP1">

                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-4">
            <div class="form-group">
              <label for="insc_municipal" class="col-form-label">Local de Estoque</label>
              <div class="col">
                <multiselect v-model="dados_pedido.local_estoque" label="descricao" :options="local_estoques">

                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-4">
            <div class="form-group">
              <label for="insc_municipal" class="col-form-label">CNPJ</label>
              <div class="col">
                <!-- se for true não digita se for false digita -->
                <input :disabled="veri_intermed" v-model="dados_pedido.cnpj_intermed" type="text"
                  class="form-control text-left" placeholder="Digite o cnpj" id="cnpj" />
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-4">
            <div class="form-group">
              <label for="insc_municipal" class="col-form-label">Identificador do Vendedor</label>
              <div class="col">
                <!-- <input  v-model="dados_pedido.identificação" type="text" class="form-control text-right"
                  placeholder="Digite o identificador" id="insc_municipal_id" /> -->
                <multiselect v-model="dados_pedido.identificação" placeholder="Selecione" label="descricao"
                  track-by="descricao" :options="pessoas" :option-height="104"
                  :custom-label="customLabel_identificador_vendedor" :show-labels="false"
                  deselect-label="ENTER para remover" select-label="Pressione ENTER">
                  <template slot="singleLabel" slot-scope="props">
                    <span class="option__desc">
                      <span class="option__title">{{ props.option.id }} - {{
                        props.option.pes_fantasia }}</span>
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title">{{ props.option.id }} - {{
                        props.option.pes_fantasia }}</span>
                    </div>
                  </template><span slot="noResult">Opa! Nenhum registro encontrado</span>
                </multiselect>

              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-4">
            <div class="form-group">
              <label for="insc_municipal" class="col-form-label">&nbsp;</label>
              <div class="col">
                <b-form-checkbox v-model="dados_pedido.tp_itermediador" @change="intermed_veri(1)" value="1"
                  id="checkbox3">
                  Com Intermediador
                </b-form-checkbox>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-4">
            <div class="form-group">
              <label for="insc_municipal" class="col-form-label">Tipo de Documento</label>
              <div class="col">
                <input type="radio" id="option1" class="ml-6" value="1" v-model="dados_pedido.tpnf">
                <label for="">Entrada</label>

                <!-- <b-form-checkbox v-model="dados_pedido.tpnf" value="1" id="checkbox1">
                  Entrada
                </b-form-checkbox> -->
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-4">
            <div class="form-group">
              <label for="insc_municipal" class="col-form-label">&nbsp;</label>
              <div class="col">
                <input type="radio" id="option1" class="ml-6" value="2" v-model="dados_pedido.tpnf">
                <label for="">Saida</label>
                <!-- <b-form-checkbox v-model="dados_pedido.tpnf" value="2" id="checkbox2">
                  Saída
                </b-form-checkbox> -->
              </div>
            </div>
          </div>
        </div>
      </b-card-body>
    </b-collapse>
    <b-card-header header-tag="header" class="p-1" role="tab">
      <b-button block v-b-toggle.pedido-dados-gerais variant="light">Cliente/Fornecedor</b-button>
    </b-card-header>
    <b-collapse id="pedido-dados-gerais" accordion="pedido-accordion" role="tabpanel">
      <b-card-body>
        <div class="row">
          <div class="col-sm-6 col-md-12">
            <div class="form-group">
              <label for="insc_municipal" class="col-form-label">Código/ Nome</label>
              <div class="col">
                <multiselect v-model="cliente_fornecedor.codigo" placeholder="Selecione" label="descricao"
                  track-by="descricao" :options="pessoas" :option-height="104"
                  :custom-label="customLabel_identificador_vendedor" :show-labels="false"
                  deselect-label="ENTER para remover" select-label="Pressione ENTER"
                  @select="select_tp_mov_cliente($event)">
                  <template slot="singleLabel" slot-scope="props">
                    <span class="option__desc">
                      <span class="option__title">{{ props.option.id }} - {{
                        props.option.pes_fantasia }}</span>
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title">{{ props.option.id }} - {{
                        props.option.pes_fantasia }}</span>
                    </div>
                  </template>
                  <span slot="noResult">Opa! Nenhum registro encontrado</span>
                  <button @click="abrir_modal_cliente()" slot="noResult" class="btn btn-primary ml-2">Cadastrar
                    cliente</button>
                  <template>
                    <span slot="noOptions" class="mr-2">Lista Vazia Cadastre o Cliente</span>
                    <button @click="abrir_modal_cliente()" slot="noOptions" class="btn btn-primary ml-2">Cadastrar
                      cliente</button>
                  </template>
                </multiselect>
                <!-- <multiselect v-model="cliente_fornecedor.codigo" :options="pessoas" label="id"
                  @select="selectCliente_cod($event)">
                </multiselect> -->
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-modal v-model="modal_cliente" size="xl" title="Cadastro de Cliente" hide-footer>
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-6 col-md-4">
                      <div class="form-group">
                        <label for="Bandeira" class="col-form-label">Tipo Pessoa</label>
                        <div class="col">
                          <input type="radio" id="option1" value="option1" v-model="cliente_fornecedor.tp_pessoa">
                          <label for="">Fisica</label>
                          <input type="radio" id="option2" value="option2" v-model="cliente_fornecedor.tp_pessoa">
                          <label for="">Juridica</label>
                          <input type="radio" id="option3" value="option3" v-model="cliente_fornecedor.tp_pessoa">
                          <label for="">Estrangeira</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-4">
                      <div class="form-group">
                        <label for="Bandeira" class="col-form-label">CNPJ :</label>
                        <div class="col">
                          <input v-model="cliente_fornecedor.cnpj" type="text" class="form-control text-left"
                            placeholder="00.000.000/0000-00">
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-4">
                      <div class="form-group">
                        <label for="Bandeira" class="col-form-label">Nome Fantasia :</label>
                        <div class="col">
                          <input v-model="cliente_fornecedor.n_fantasia" type="text" class="form-control text-left">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6 col-md-3">
                      <div class="form-group">
                        <label for="Bandeira" class="col-form-label">Endereço :</label>
                        <div class="col">
                          <input v-model="cliente_fornecedor.endereco" type="text" class="form-control text-left">
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-3">
                      <div class="form-group">
                        <label for="Bandeira" class="col-form-label">Número :</label>
                        <div class="col">
                          <input v-model="cliente_fornecedor.numero" type="text" class="form-control text-left">
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-3">
                      <div class="form-group">
                        <label for="Bandeira" class="col-form-label">Bairro :</label>
                        <div class="col">
                          <input v-model="cliente_fornecedor.bairro" type="text" class="form-control text-left">
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-3">
                      <div class="form-group">
                        <label for="Bandeira" class="col-form-label">CEP :</label>
                        <div class="col">
                          <input v-model="cliente_fornecedor.cep" type="text" class="form-control text-left"
                            placeholder="00000-000">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6 col-md-3">
                      <div class="form-group">
                        <label for="Bandeira" class="col-form-label">Pais :</label>
                        <div class="col">
                          <multiselect v-model="cliente_fornecedor.pais" :options="paises"
                            @select="pais_selecionado($event)" label="nome">
                          </multiselect>
                          <!-- <input v-model="credito.bandeira" type="text" class="form-control text-left"> -->
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-3">
                      <div class="form-group">
                        <label for="Bandeira" class="col-form-label">UF :</label>
                        <div class="col">
                          <multiselect v-model="cliente_fornecedor.uf" :options="estados" label="nome"
                            @select="select_cidade_por_uf($event)">
                          </multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-3">
                      <div class="form-group">
                        <label for="Bandeira" class="col-form-label">Cidade :</label>
                        <div class="col">
                          <multiselect v-model="cliente_fornecedor.cidade" :options="cidades_ibge1" label="nome">
                          </multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-3">
                      <div class="form-group">
                        <label for="Bandeira" class="col-form-label">Complemento :</label>
                        <div class="col">
                          <input v-model="cliente_fornecedor.complemento" type="text" class="form-control text-left">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6 col-md-6">
                      <div class="form-group">
                        <label for="Bandeira" class="col-form-label">Telefone :</label>
                        <div class="col">
                          <input v-model="cliente_fornecedor.telefone" type="text" class="form-control text-left"
                            placeholder="(00) 00000-0000">
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-6">
                      <div class="form-group">
                        <label for="Bandeira" class="col-form-label">E-mail :</label>
                        <div class="col">
                          <input v-model="cliente_fornecedor.email" type="text" class="form-control text-left">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6 col-md-3">
                      <div class="form-group">
                        <label for="Bandeira" class="col-form-label">Indicador de IE :</label>
                        <div class="col">
                          <!-- indicadores_IE -->
                          <!-- <input v-model="credito.bandeira" type="text" class="form-control text-left"> -->
                          <multiselect v-model="cliente_fornecedor.i_IE" :options="indicadores_IE" label="nome">

                          </multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-3">
                      <div class="form-group">
                        <label for="Bandeira" class="col-form-label">IE :</label>
                        <div class="col">
                          <input v-model="cliente_fornecedor.ie" type="text" class="form-control text-left">
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-3">
                      <div class="form-group">
                        <label for="Bandeira" class="col-form-label">Inscrição Municipal :</label>
                        <div class="col">
                          <input v-model="cliente_fornecedor.insc_munic" type="text" class="form-control text-left">
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-3">
                      <div class="form-group">
                        <label for="Bandeira" class="col-form-label">Inscrição Suframa :</label>
                        <div class="col">
                          <input v-model="cliente_fornecedor.insc_suframa" type="text" class="form-control text-left">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-6 col-md-6">
                      <div class="form-group">
                        <!-- <label for="Bandeira" class="col-form-label"></label> -->
                        <div class="col">
                          <button class="btn btn-primary" @click="back_modal_cliente()">Salvar</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-modal>

          </div>

        </div>

      </b-card-body>
    </b-collapse>
    <b-card-header header-tag="header" class="p-1" role="tab">
      <b-button block v-b-toggle.referenciados variant="light">Planos de contas</b-button>
    </b-card-header>
    <b-collapse id="referenciados" accordion="referenciados-accordion" role="tabpanel">
      <b-card-body>
        <div class="row">
          <div style="overflow-x: auto">
            <b-table-simple>
              <b-thead id="b-head">
                <b-tr>
                  <b-th class="b-th">Código</b-th>
                  <b-th class="b-th">Código C custo</b-th>
                  <b-th class="b-th">Débito</b-th>
                  <b-th class="b-th">Código Centro Resultado</b-th>
                  <b-th class="b-th">Crédito</b-th>
                  <b-th class="b-th">Valor</b-th>
                  <b-th class="b-th">Ativo</b-th>
                  <!-- <b-th class="b-th">Ações</b-th> -->
                  <b-th>&nbsp;</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td>
                    <input id="largura" v-model="id" class="form-control text-let" />
                  </b-td>
                  <b-td>
                    <input id="largura" v-model="cod_c_custo" class="form-control text-let" />
                  </b-td>
                  <b-td>
                    <multiselect v-model="centrocusto_id" placeholder="Selecione" label="descricao" track-by="descricao"
                      :options="centro_custos" :option-height="104" :custom-label="customLabelCentro_custo"
                      :show-labels="false" deselect-label="ENTER para remover" select-label="Pressione ENTER"
                      class="custom-multiselect-wrapper">
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.id }} - {{
                            props.option.identificacao }}</span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.id }} - {{
                            props.option.identificacao }}</span>
                        </div>
                      </template><span slot="noResult">Opa! Nenhum registro encontrado</span>
                    </multiselect>
                    <!-- <multiselect v-model="centrocusto_id" class="custom-multiselect-wrapper" :options="centro_custos" label="identificacao">

                  </multiselect> -->
                  </b-td>
                  <b-td>
                    <input id="largura" v-model="cod_res" class="form-control text-let" />
                  </b-td>
                  <b-td>
                    <multiselect v-model="centroresultado_id" placeholder="Selecione" label="descricao"
                      track-by="descricao" :options="centro_resultados" :option-height="104"
                      :custom-label="customLabelCentro_resultado" :show-labels="false" deselect-label="ENTER para remover"
                      select-label="Pressione ENTER" class="custom-multiselect-wrapper">
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.id }} - {{
                            props.option.identificacao }}</span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.id }} - {{
                            props.option.identificacao }}</span>
                        </div>
                      </template><span slot="noResult">Opa! Nenhum registro encontrado</span>
                    </multiselect>
                    <!-- <multiselect v-model="centroresultado_id" class="custom-multiselect-wrapper" :options="centro_resultados" label="identificacao">
                  </multiselect> -->
                  </b-td>
                  <b-td>
                    <input id="largura" v-model="valor" @input="formatInput_valor_cent()" class="form-control text-let" />
                  </b-td>
                  <b-td>
                    <input id="largura" v-model="ativo" class="form-control text-let" />
                  </b-td>
                  <!-- <b-td>
                    <button @click.prevent="add_c_custo()" class="btn btn-danger">Adicionar</button>
                  </b-td> -->
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
          <b-table striped hover :items="centro_custo_vet" responsive id="doc-itens" :fields="fields">
            <template #cell(excluir)="row">
              <button @click="Delete_cent_custo(row.item)" class="btn btn-danger">Excluir</button>
            </template>
          </b-table>
        </div>
        <div class="row">
          <div class="col">
            <div class="col-md-12 mt-10">
              <button class="btn btn-primary" @click.prevent="add_c_custo()">
                Adicionar
              </button>
              <div v-if="verifica_doc">
                <docRef :abrir_modal="verifica_doc" @back=back>
                </docRef>
              </div>
            </div>
          </div>
        </div>
      </b-card-body>
    </b-collapse>
    <b-card-header header-tag="header" class="p-1" role="tab">
      <b-button block v-b-toggle.produtos variant="light">Produtos</b-button>
    </b-card-header>
    <b-collapse id="produtos" accordion="pedido-accordion" role="tabpanel">
      <div class="card-body">
        <b-table-simple>
          <b-thead id="b-head">
            <b-tr>
              <!-- <b-th class="b-th" id="largura2">Seq</b-th> -->
              <b-th class="b-th">descricao</b-th>
              <b-th class="b-th">Un Medida</b-th>
              <b-th class="b-th">CFOP</b-th>
              <b-th class="b-th">Quantidade</b-th>
              <b-th class="b-th">Vlr Unitario</b-th>
              <b-th class="b-th">Desconto &nbsp; 
                &nbsp; % ou Vlr</b-th>
              <b-th class="b-th">Vlr Bruto</b-th>
              <b-th>&nbsp;</b-th>
              <b-th>&nbsp;</b-th>
              <b-th>&nbsp;</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(item, index) in items1" :key="index">
              <!-- <td>
                <input id="inputId" class="form-control text-let" v-model="item.id" value="index+1" type="hidden"
                  disabled />
                <input id="id_seq" class="form-control text-let" v-bind:value="index + 1" disabled />
              </td> -->
              <b-td>
                <multiselect class="custom-multiselect-wrapper_desc" v-model="item_descri[index].descric1"
                  :options="produtos" label="produto_nome" placeholder="Buscar ou selecionar produtos..."
                  @select="atualizarValores(index, $event),soma_dos_itens()">
                  <template slot="option" slot-scope="{ option }">
                    {{ option.produto_nome }}
                  </template>
                </multiselect>
              </b-td>
              <b-td>
                <multiselect class="custom-multiselect-wrapper1" v-model="item.unidade" :options="listUnidades"
                  placeholder="Selecione" track-by="id" deselect-label="Pressione ENTER para remover" :show-labels="false"
                  :searchable="false">
                </multiselect>
              </b-td>
              <b-td>
                <multiselect class="custom-multiselect-wrapper1" v-model="item_descri[index].cfop2" :options="listCFOP1"
                  placeholder="Selecione" deselect-label="Pressione ENTER para remover">
                </multiselect>
              </b-td>
              <b-td><input id="largura2" type="number" min="1" class="form-control text-let" v-model="item.Quantidade"
                  placeholder="Quantidade" @input="calcularValorBruto(index)" /></b-td>
              <b-td>
                <input id="largura1" pattern="[0-9]+" required title="Somente números são permitidos"
                  class="form-control text-let" type="text" v-model="item.Unitario"
                  @input="formatInput_valor_unit(index), calcularValorBruto(index), soma_dos_itens()" placeholder="Unitario" /></b-td>
              <b-td>
                <input id="largura1" pattern="[0-9]+" required title="Somente números são permitidos"
                  class="form-control text-let" type="text" placeholder="R$ ou % " v-model="item.Desconto"
                  @input="formatInput_desc(index), soma_dos_itens()" />
                <!-- calcularValorBruto(index) -->
                <!-- calcularDesconto(index) -->
                <!--  -->
              </b-td>
              <b-td>
                <input id="largura1" pattern="[0-9]+" required title="Somente números são permitidos"
                  class="form-control text-let" v-model="item.Bruto" placeholder="Bruto" />
              </b-td>
              <b-td>
                <button id="roxo" v-on:click="showItem(index)" v-tooltip.bottom="'Visualizar'">
                  <i class="fas fa-eye"></i>
                </button>
              </b-td>
              <b-td>
                <button id="er2" v-on:click="envio(index)" v-tooltip.bottom="'Editar'" v-b-modal.modal-xl>
                  <i class="fas fa-edit"></i>
                </button>
              </b-td>
              <b-td>
                <button id="er" label="No animation" v-tooltip.bottom="'Excluir'" v-on:click="removeItem(index), soma_dos_itens()">
                  <i class="fas fa-trash"></i>
                </button>
              </b-td>
            </b-tr>
          </b-tbody>
          <div>
            <!-- this.tem_desconto = true; -->
            <b-modal  id="modal-xl" size="xl" title="Item Produto" :options="variants">
              <!-- produto_atual -->
              
              <insertpedido :empresa_origem="empresa_atual" :destinatario="pessoas" :produto_atual="produto_atual" :tem_desconto="tem_desconto" :list_cfop="listCFOP1" :Produtos="produtos" :oldPedido="pedido_receb" :sub="Lista_Produtos" />
            </b-modal>
          </div>
        </b-table-simple>
      </div>
      <div class="col-md-12 mt-10">
        <button class="btn btn-primary" v-on:click="addItem">
          Adicionar Produto
        </button>
      </div>
    </b-collapse>
    <b-card-header header-tag="header" class="p-1" role="tab">
      <b-button block v-b-toggle.frete variant="light">Fatura</b-button>
    </b-card-header>
    <b-collapse id="frete" accordion="pedido-accordion" role="tabpanel">
      <b-card-body>
        <b-modal v-model="modal_credito" size="xl" title="informação Credito" hide-footer>
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-6 col-md-6">
                  <div class="form-group">
                    <label for="Bandeira" class="col-form-label">Número de Ordem :</label>
                    <div class="col">
                      <input v-model="credito.ordem" type="text" class="form-control text-right">
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6">
                  <div class="form-group">
                    <label for="Bandeira" class="col-form-label">Bandeira do Cartão :</label>
                    <div class="col">
                      <input v-model="credito.bandeira" type="text" class="form-control text-right">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-6 col-md-6">
                  <div class="form-group">
                    <!-- <label for="Bandeira" class="col-form-label"></label> -->
                    <div class="col">
                      <button class="btn btn-primary" @click="back_modal_credito()">Salvar</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
        <b-modal v-model="modal_debito" size="xl" title="informação debito" hide-footer>
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-6 col-md-6">
                  <div class="form-group">
                    <label for="Bandeira" class="col-form-label">Número de Ordem :</label>
                    <div class="col">
                      <input v-model="debito.ordem" type="text" class="form-control text-right">
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6">
                  <div class="form-group">
                    <label for="Bandeira" class="col-form-label">Bandeira do Cartão :</label>
                    <div class="col">
                      <input v-model="debito.bandeira" type="text" class="form-control text-right">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-6 col-md-6">
                  <div class="form-group">
                    <!-- <label for="Bandeira" class="col-form-label"></label> -->
                    <div class="col">
                      <button class="btn btn-primary" @click.prevent="back_modal_debito()">Salvar</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
        <!-- 
          <b-th class="b-th" v-if="veri_cheque">N chque</b-th>
                  <b-th class="b-th" v-if="veri_cheque">Emitente</b-th>
                  <b-th class="b-th" v-if="veri_cheque">CNPJ</b-th>
                  <b-th class="b-th" v-if="veri_cheque">Ag</b-th>
                  <b-th class="b-th" v-if="veri_cheque">N cc</b-th>
                  <b-th class="b-th" v-if="veri_cheque">Banco</b-th>
                  <b-th class="b-th" v-if="veri_cheque">Valor</b-th>
         -->
        <b-modal v-model="modal_cheque" size="xl" title="informação cheque" hide-footer>
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-6 col-md-3">
                  <div class="form-group">
                    <label for="Bandeira" class="col-form-label">N chque :</label>
                    <div class="col">
                      <input v-model="cheque.n_cheque" type="text" class="form-control text-right">
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div class="form-group">
                    <label for="Bandeira" class="col-form-label">Emitente :</label>
                    <div class="col">
                      <input v-model="cheque.emitente" type="text" class="form-control text-right">
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div class="form-group">
                    <label for="Bandeira" class="col-form-label">CNPJ :</label>
                    <div class="col">
                      <input v-model="cheque.cnpj" type="text" class="form-control text-right">
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div class="form-group">
                    <label for="Bandeira" class="col-form-label">Ag :</label>
                    <div class="col">
                      <input v-model="cheque.ag" type="text" class="form-control text-right">
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-4">
                  <div class="form-group">
                    <label for="Bandeira" class="col-form-label">N cc :</label>
                    <div class="col">
                      <input v-model="cheque.ncc" type="text" class="form-control text-right">
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-4">
                  <div class="form-group">
                    <label for="Bandeira" class="col-form-label">Banco :</label>
                    <div class="col">
                      <input v-model="cheque.banco" type="text" class="form-control text-right">
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-4">
                  <div class="form-group">
                    <label for="Bandeira" class="col-form-label">Valor :</label>
                    <div class="col">
                      <input v-model="cheque.valor" type="text" class="form-control text-right">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-6 col-md-6">
                  <div class="form-group">
                    <!-- <label for="Bandeira" class="col-form-label"></label> -->
                    <div class="col">
                      <button class="btn btn-primary" @click.prevent="back_modal_cheque()">Salvar</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
        <div class="row">
          <div class="col-md-12">
            <!-- <div style="overflow-x: auto; overflow-y:none;"> -->
            <b-table-simple>
              <b-thead id="b-head">
                <b-tr>
                  <!-- {{verifica_tipos}} -->
                  <b-th class="b-th">Número da Fatura</b-th>
                  <b-th class="b-th">Tipo de Pagamento</b-th>
                  <b-th class="b-th">Quantidae de Parcelas</b-th>
                  <b-th class="b-th">Quantidade de Dias</b-th>
                  <b-th class="b-th">Data da primeira Parcela</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td>
                    <!-- :id="{ 'largura': veri_credito === true }" -->
                    <input :id="{ 'largura': verifica_tipos === true }" v-model="fatura.num_fatura" type="text"
                      :class="{ 'form-control text-right largura': verifica_tipos === true }"
                      class="form-control text-right">
                  </b-td>
                  <b-td>
                    <multiselect v-model="fatura.tipo_pagamento_id" placeholder="Selecione" track-by="tiponome"
                      label="tiponome" :options="pagamentos_tp" :option-height="104"
                      :custom-label="customLabelCentro_tipo_pagamento" :show-labels="false"
                      deselect-label="ENTER para remover" select-label="Pressione ENTER" @select="select_tp_pague($event)"
                      class="custom-multiselect-wrapper">
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.id }} - {{
                            props.option.tiponome }}</span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.id }} - {{
                            props.option.tiponome }}</span>
                        </div>
                      </template><span slot="noResult">Opa! Nenhum registro encontrado</span>
                    </multiselect>

                  </b-td>
                  <b-td>
                    <input :id="{ 'largura': verifica_tipos === true }" v-model="fatura.qtde_parcelas" type="text"
                      :class="{ 'form-control text-right largura': verifica_tipos === true }"
                      class="form-control text-right">
                  </b-td>
                  <b-td>
                    <input :id="{ 'largura': verifica_tipos === true }" v-model="fatura.qtd_dias" type="text"
                      :class="{ 'form-control text-right largura': verifica_tipos === true }"
                      class="form-control text-right">
                  </b-td>
                  <b-td>
                    <input :id="{ 'largura': verifica_tipos === true }" v-model="fatura.dt_parcela" type="date"
                      :class="{ 'form-control text-right largura': verifica_tipos === true }"
                      class="form-control text-right" placeholder="selecione a data" />
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>

          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 col-md-12">
            <button class="btn btn-primary" @click.prevent="gerar_parcelas()">
              Gerar
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 col-md-2">
            <div class="form-group">
              <label for="insc_municipal" class="col-form-label">Número Fatura</label>
              <div class="col">
              </div>
            </div>
          </div>

          <b-table striped hover :items="fatura_vet" responsive id="doc-itens" :fields="fields_fatura">
            <template #cell(tp_pagamento)="row">
              {{ (row.item.tp_pagamento === 1) ? 'Dinheiro'
                : (row.item.tp_pagamento === 2) ? 'Duplicata'
                  : (row.item.tp_pagamento === 3) ? 'Cheque'
                    : (row.item.tp_pagamento === 4) ? 'Cartão de Credito'
                      : (row.item.tp_pagamento === 5) ? 'Cartão de Debito'
                        : (row.item.tp_pagamento === 6) ? 'Cupom Devolução'
                          : (row.item.tp_pagamento === 7) ? 'Transferencia'
                            : (row.item.tp_pagamento === 8) ? 'Vale Alimentação'
                              : (row.item.tp_pagamento === 9) ? 'Vale Refeição'
                                : (row.item.tp_pagamento === 10) ? 'Vale Presente'
                                  : (row.item.tp_pagamento === 11) ? 'Vale Combustivel'
                                    : (row.item.tp_pagamento === 12) ? 'Sem Pagamento' : 'Outros'
              }}
            </template>
            <template #cell(valor)="row">
              <span class="text-right">{{ formatterCurrBR(row.item.valor) }}</span>
            </template>
            <template #cell(excluir)="row">
              <button @click="Delete_fatura(row.item)" class="btn btn-danger">Excluir</button>
            </template>
            <!-- <template #cell(excluir2)="row">
              <multiselect v-model="nada" @select="fat(row.item)" label="tiponome" :options="pagamentos_tp"></multiselect>
            </template> -->
            <!-- <template #cell(tipo_pagamento_id)="row">
              {{ (row.item.tipo_pagamento_id === 1)  ? 'Dinheiro' 
              :  (row.item.tipo_pagamento_id === 2)  ? 'Duplicata' 
              :  (row.item.tipo_pagamento_id === 3)  ? 'Cheque' 
              :  (row.item.tipo_pagamento_id === 4)  ? 'Cartão de Credito' 
              :  (row.item.tipo_pagamento_id === 5)  ? 'Cartão de Debito' 
              :  (row.item.tipo_pagamento_id === 6)  ? 'Cupom Devolução' 
              :  (row.item.tipo_pagamento_id === 7)  ? 'Transferencia' 
              :  (row.item.tipo_pagamento_id === 8)  ? 'Vale Alimentação' 
              :  (row.item.tipo_pagamento_id === 9)  ? 'Vale Refeição' 
              :  (row.item.tipo_pagamento_id === 10) ? 'Vale Presente' 
              :  (row.item.tipo_pagamento_id === 11) ? 'Vale Combustivel' 
              :  (row.item.tipo_pagamento_id === 12) ? 'Sem Pagamento' : 'Outros' 
                }}
            </template> -->
          </b-table>
        </div>
      </b-card-body>
    </b-collapse>
    <b-card-header header-tag="header" class="p-1" role="tab">
      <b-button block v-b-toggle.totais variant="light">Totais</b-button>
    </b-card-header>
    <b-collapse id="totais" accordion="pedido-accordion" role="tabpanel">
      <b-card-body>
        <div class="row">
          <div class="col-sm-6 col-md-3">
            <div class="form-group">
              <label for="insc_municipal" class="col-form-label">Valor dos Produtos</label>
              <div class="col">
                <input v-model="totais.vprod" type="text" class="form-control text-right" placeholder="digite"
                  id="insc_municipal_id" @input="formatInput_valor_prod_totais()" />
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3">
            <div class="form-group">
              <label for="insc_municipal" class="col-form-label">Valor dos Serviços</label>
              <div class="col">
                <input v-model="totais.vl_servicos" type="text" class="form-control text-right" placeholder="digite"
                  id="insc_municipal_id" @input="formatInput_valor_servc_totais()" />
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3">
            <div class="form-group">
              <label for="insc_municipal" class="col-form-label">Valor dos Descontos</label>
              <div class="col">
                <input v-model="totais.vdesc" type="text" class="form-control text-right" placeholder="digite"
                  id="insc_municipal_id" @input="formatInput_valor_desc_totais()" />
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3">
            <div class="form-group">
              <label for="insc_municipal" class="col-form-label">Valor do ICMS</label>
              <div class="col">
                <input v-model="totais.vl_icms" type="text" class="form-control text-right" placeholder="digite"
                  id="insc_municipal_id" @input="formatInput_valor_imcs_totais()" />
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-12">
            <div class="form-group">
              <label for="insc_municipal" class="col-form-label">Valor Total da NF-e</label>
              <div class="col">
                <!-- vl_tot_nfe -->
                <input v-model="totais.vnf" type="text" class="form-control text-right" placeholder="digite"
                  id="insc_municipal_id" @input="formatInput_valor_tot_totais()" />
              </div>
            </div>
          </div>
        </div>
      </b-card-body>
    </b-collapse>
    <b-card-header header-tag="header" class="p-1" role="tab">
      <b-button block v-b-toggle.cobranca variant="light">Observações</b-button>
    </b-card-header>
    <b-collapse id="cobranca" accordion="pedido-accordion" role="tabpanel">
      <b-card-body>
        <div class="card-body">
          <div class="row">

          </div>
          <div class="card-body d-flex justify-content-center align-items-center" id="top">
            <div class="col-md-12 mt-12 text-center"> <!-- Adicionado "text-center" aqui -->

            </div>
          </div>
          <div class="row">

          </div>
          <div class="row">
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="insc_municipal" class="col-form-label">Informações Adicionais</label>
                <div class="col">
                  <textarea name="" id="" cols="30" rows="10" class="form-control text-right"></textarea>
                  <span class="mr-2"><a href="#" @click.prevent="addAdicionais()">{{ condicao ? '+' : '-' }}Informações
                      de Fisco</a></span>
                </div>
              </div>
            </div>
            <div class="col-md-12" v-if="!condicao">
              <div class="form-group">
                <label for="insc_municipal" class="col-form-label">Informações do Interesse do Fisco</label>
                <div class="col">
                  <textarea name="" id="" cols="30" rows="10" class="form-control text-right"></textarea>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label for="insc_municipal" class="col-form-label">&nbsp;</label>
                <div class="col">
                  <span class="mr-2"><a href="#" @click.prevent="abrir_autorizada_modal()">Autorizadas à Baixa do
                      Xml</a></span>
                  <span class="ml-2"><a href="#" @click.prevent="abrir_contribuinte_modal()">Observação do
                      Contribuinte</a></span>
                  <span class="ml-2"><a href="#" @click.prevent="abrir_referenciado_modal()">Processo
                      Referenciado</a></span>
                  <span class="ml-2"><a href="#" @click.prevent="abrir_info_compra_modal()">Informções de
                      Compras</a></span>
                  <span class="ml-2"><a href="#" @click.prevent="abrir_exportacao_modal()">Exportação</a></span>
                  <div v-if="autorizada">
                    <autorizada :abrir1="autorizada" @back="back">
                    </autorizada>
                  </div>
                  <div v-if="contribuinte">
                    <contribuinte :abrir1="contribuinte" @back="back">
                    </contribuinte>
                  </div>
                  <div v-if="referenciado">
                    <referenciado :abrir1="referenciado" @back="back">
                    </referenciado>
                  </div>
                  <div v-if="info_compra">
                    <info_compra :abrir1="info_compra" @back="back">
                    </info_compra>
                  </div>
                  <div v-if="exportacao">
                    <exportacao :abrir1="exportacao" @back="back">
                    </exportacao>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label for="insc_municipal" class="col-form-label">&nbsp;</label>
                <div class="col">
                  <button class="btn btn-secondary" id="butt">
                    Emitir NF-e
                  </button>
                  <button class="btn btn-secondary ml-4" id="butt">
                    Pré-visualizar o DANFE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-card-body>
    </b-collapse>
    <b-card-header header-tag="header" class="p-1" role="tab">
      <b-button block v-b-toggle.transporte variant="light">Transporte</b-button>
    </b-card-header>
    <b-collapse id="transporte" accordion="pedido-accordion" role="tabpanel">
      <b-card-body>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="insc_municipal" class="col-form-label">Modalidade do Frete</label>
                <div class="col">
                  <multiselect v-model="modfrete" :options="modalidade_transportes">
                  </multiselect>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <b-tabs card>
                <b-tab title="Transportador" active>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="insc_municipal" class="col-form-label">Código/Nome da Transportadora</label>
                        <div class="col">
                          <multiselect :options="series" v-model="transp_xnome">
                          </multiselect>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab>
                <b-tab title="Retenção ICMS">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="insc_municipal" class="col-form-label">Base de Calculo</label>
                        <div class="col">
                          <input v-model="bs_calc" type="text" @input="formatInput_base_calc()"
                            class="form-control text-left" placeholder="digite a base de calculo">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="insc_municipal" class="col-form-label">Alíquota de Retenção</label>
                        <div class="col">
                          <input v-model="alqt_retencao" @input="formatInput_alqt_ret()" type="text"
                            class="form-control text-left" placeholder="digite a alíquota">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="insc_municipal" class="col-form-label">Valor do Serviço</label>
                        <div class="col">
                          <input type="text" v-model="transp_vserv" @input="formatInput_vl_servc()"
                            class="form-control text-left" placeholder="digite o valor do serviço">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="insc_municipal" class="col-form-label">Valor do Icms Retido</label>
                        <div class="col">
                          <input v-model="vl_icms_ret" @input="formatInput_vl_servc_icms()" type="text"
                            class="form-control text-left" placeholder="digite a base de calculo">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="insc_municipal" class="col-form-label">Estado</label>
                        <div class="col">
                          <multiselect v-model="est" :options="estados" label="nome"
                            @select="select_cidade_por_uf($event)">
                          </multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="insc_municipal" class="col-form-label">Cidade</label>
                        <div class="col">
                          <multiselect v-model="cidad" :options="cidades_ibge1" label="nome">
                          </multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="insc_municipal" class="col-form-label">CFOP</label>
                        <div class="col">
                          <multiselect v-model="ret_cfop" :options="listCFOP1">
                          </multiselect>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab>
                <b-tab title="Meio de Transporte">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="insc_municipal" class="col-form-label">Placa</label>
                        <div class="col">
                          <multiselect v-model="transp_placa" :options="placas">
                          </multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="insc_municipal" class="col-form-label">UF</label>
                        <div class="col">
                          <multiselect v-model="transp_uf" :options="UFS">
                          </multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="insc_municipal" class="col-form-label">RNTC</label>
                        <div class="col">
                          <multiselect v-model="transp_rntc" :options="series">
                          </multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="insc_municipal" class="col-form-label">&nbsp;</label>
                        <div class="col">
                          <button class="btn btn-primary" @click.prevent="add_transporte()">
                            Adicionar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <b-table striped hover :items="meio_transporte" responsive id="doc-itens" :fields="fields_transporte">
                    <template #cell(excluir)="row">
                      <button @click="Delete_meio_transporte(row.item)" class="btn btn-danger">Excluir</button>
                    </template>
                  </b-table>
                </b-tab>
                <b-tab title="Volume">
                  <div class="row">
                    <div class="col-md-12">
                      <b-table-simple>
                        <b-thead id="b-head">
                          <b-tr>
                            <b-th class="b-th">Item</b-th>
                            <b-th class="b-th">Quantidade </b-th>
                            <b-th class="b-th">Especie</b-th>
                            <b-th class="b-th">Marca</b-th>
                            <b-th class="b-th">Número Volume</b-th>
                            <b-th class="b-th">Peso Liquido</b-th>
                            <b-th class="b-th">Peso Bruto</b-th>
                            <b-th class="b-th">Ações</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr>
                            <b-td>
                              <input v-model="item" type="text" class="form-control text-left">
                            </b-td>
                            <b-td>
                              <input v-model="Volume" type="text" class="form-control text-left">
                            </b-td>
                            <b-td>
                              <input v-model="especie" type="text" class="form-control text-left">
                            </b-td>
                            <b-td>
                              <input v-model="marca" type="text" class="form-control text-left">
                            </b-td>
                            <b-td>
                              <input v-model="num_vol" type="text" class="form-control text-left">
                            </b-td>
                            <b-td>
                              <input v-model="peso_liq" type="text" class="form-control text-left">
                            </b-td>
                            <b-td>
                              <input v-model="peso_bt" type="text" class="form-control text-left">
                            </b-td>
                            <b-td>
                              <button class="btn btn-primary" @click.prevent="add_volume()">
                                Adicionar
                              </button>
                            </b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </div>
                  </div>
                  <b-table striped hover :items="volumes" responsive id="doc-itens" :fields="fields4">
                    <template #cell(excluir)="row">
                      <button @click="delete_volume(row.item)" class="btn btn-danger">Excluir</button>
                    </template>
                  </b-table>
                </b-tab>
                <b-tab title="Lacre">
                  <div class="row">
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="insc_municipal" class="col-form-label">Vollacredid</label>
                        <div class="col">
                          <input v-model="volcredid" type="text" class="form-control text-left">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="insc_municipal" class="col-form-label">Volume Transporte ID</label>
                        <div class="col">
                          <input v-model="vol_transp_id" type="text" class="form-control text-left">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="insc_municipal" class="col-form-label">Item</label>
                        <div class="col">
                          <input v-model="item_lacre" type="text" class="form-control text-left">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="insc_municipal" class="col-form-label">Número do Lacre</label>
                        <div class="col">
                          <input v-model="num_lacre" type="text" class="form-control text-left">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="insc_municipal" class="col-form-label">&nbsp;</label>
                        <div class="col">
                          <button class="btn btn-primary" @click.prevent="add_lacre()">
                            add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <b-table striped hover :items="lacres" responsive id="doc-itens" :fields="fields5">
                    <template #cell(excluir)="row">
                      <button @click="delete_lacre(row.item)" class="btn btn-danger">Excluir</button>
                    </template>
                  </b-table>
                </b-tab>
              </b-tabs>
            </div>
          </div>
          <div class="row">
          </div>
          <div class="row">
          </div>
          <div class="row">
          </div>
        </div>
      </b-card-body>
    </b-collapse>
    <b-card-header header-tag="header" class="p-1" role="tab">
      <b-button block v-b-toggle.info_doc variant="light">Informações de Documentos Fiscais Referenciado</b-button>
    </b-card-header>
    <b-collapse id="info_doc" accordion="pedido-accordion" role="tabpanel">
      <b-card-body>
        <div class="card-body">
          <div class="row">
            <b-table-simple>
              <b-thead id="b-head">
                <b-tr>
                  <b-th class="b-th">Item</b-th>
                  <b-th class="b-th">Cupom ID </b-th>
                  <b-th class="b-th">Chave de Acesso</b-th>
                  <b-th>&nbsp;</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td>
                    <input v-model="item_ref" class="form-control text-let" />
                  </b-td>
                  <b-td>
                    <input v-model="id_cupom" class="form-control text-let" />
                  </b-td>
                  <b-td>
                    <input v-model="chave_acesso" class="form-control text-let" />
                  </b-td>
                  <b-td>
                    <button @click.prevent="add_info_doc()" class="btn btn-danger">Adicionar</button>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
          <b-table striped hover :items="ref_vet" responsive id="doc-itens" :fields="fields_ref">
            <template #cell(excluir)="row">
              <button @click="delete_info_doc(row.item)" class="btn btn-danger">Excluir</button>
            </template>
          </b-table>
        </div>
      </b-card-body>
    </b-collapse>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <button class="btn btn-primary" @click.prevent="setPost()">
              Salvar
            </button>
            <button class="btn btn-primary ml-2">
              imprimir
            </button>
            <button class="btn btn-primary ml-2">
              Atualizar
            </button>
            <button class="btn btn-primary ml-2">
              Carnê
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
#largura {
  width: 140px;
}

#largura1 {
  width: 80px;
}

#largura2 {
  width: 60px;
}

.largura {
  width: 140px;
}

#id_seq {
  width: 44px;
}

#unidade_id {
  width: 77px;
}

#desc {
  width: 200px;
}

#cfop_id {
  width: 77px;
}

#qtd_id {
  width: 98px;
}

#bruto_id,
#desconto_id,
#liquido_id {
  width: 86px;
}

/* .b-th,
.b-td {
  margin: 0;
  padding: 1px; 
  width:1%;
} */
.green-header {
  background-color: greenyellow;
}

#espaco {
  margin-top: 60px;
}

#espaco1 {
  margin-top: 40px;
  font-weight: bold;
}

i {
  color: white;
}

/* isso alterando os heads */
#roxo {
  background-color: #5664d2;
}

#er {
  background-color: red;
}

#er2 {
  background-color: green;
}

#b-head {
  background-color: #5664d2;
}

.b-th {
  color: white;
}

/* label,
input {
  position: relative;
  width: 78px;
} */
label::after {
  content: "" attr(unit);
  position: absolute;
  top: 3px;
  left: 45px;
  font-family: arial, helvetica, sans-serif;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
}

.multiselect__content-wrapper {
  max-height: none !important;
}

.max-height {
  max-height: none !important;
}

.table-wrapper {
  display: flex;
  align-items: flex-start;
  /* Alinha os itens verticalmente ao topo */
  overflow: hidden;
  /* Esconde qualquer conteúdo que ultrapasse o contêiner */
}

/* Suponha que você tenha uma classe para a tabela responsiva, por exemplo: */
.responsive-table {
  /* Defina um z-index menor para a tabela responsiva */
  z-index: 1;
}

.custom-multiselect-wrapper {
  width: 150px;
}

.custom-multiselect-wrapper_desc {
  width: 140px;
}

.custom-multiselect-wrapper1 {
  width: 100px;
  /* Ajuste a largura conforme necessário */
}
</style>