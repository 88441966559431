// arquivo vendas_controller.js

class Vendas {
  constructor() {
    this._pBcICMS = 2;
    this._pIPI = 5;
    this._pBC_PIS = 4;
    this._pbc_IPI = 3;
    this._pPIS=4;
    this._pBc_COFINS=3;
    this._pCOFINS= 2;
    this._pICMS=1;
    this.tabela = { //testando desta forma pode facilitar na chamada e diminuir as linhas de codigo;
      "AC": { aliquota: 0.17, reducaoBaseCalculo: 0.00 },
      "AL": { aliquota: 0.18, reducaoBaseCalculo: 0.00 },
      "AP": { aliquota: 0.18, reducaoBaseCalculo: 0.00 },
      "AM": { aliquota: 0.18, reducaoBaseCalculo: 0.00 },
      "BA": { aliquota: 0.18, reducaoBaseCalculo: 0.00 },
      "CE": { aliquota: 0.18, reducaoBaseCalculo: 0.00 },
      "DF": { aliquota: 0.18, reducaoBaseCalculo: 0.00 },
      "ES": { aliquota: 0.17, reducaoBaseCalculo: 0.00 },
      "GO": { aliquota: 0.17, reducaoBaseCalculo: 0.00 },
      "MA": { aliquota: 0.18, reducaoBaseCalculo: 0.00 },
      "MT": { aliquota: 0.17, reducaoBaseCalculo: 0.00 },
      "MS": { aliquota: 0.17, reducaoBaseCalculo: 0.00 },
      "MG": { aliquota: 0.18, reducaoBaseCalculo: 0.00 },
      "PA": { aliquota: 0.17, reducaoBaseCalculo: 0.00 },
      "PB": { aliquota: 0.18, reducaoBaseCalculo: 0.00 },
      "PR": { aliquota: 0.18, reducaoBaseCalculo: 0.00 },
      "PE": { aliquota: 0.18, reducaoBaseCalculo: 0.00 },
      "PI": { aliquota: 0.18, reducaoBaseCalculo: 0.00 },
      "RJ": { aliquota: 0.20, reducaoBaseCalculo: 0.00 },
      "RN": { aliquota: 0.18, reducaoBaseCalculo: 0.00 },
      "RS": { aliquota: 0.18, reducaoBaseCalculo: 0.00 },
      "RO": { aliquota: 0.17, reducaoBaseCalculo: 0.00 },
      "RR": { aliquota: 0.17, reducaoBaseCalculo: 0.00 },
      "SC": { aliquota: 0.17, reducaoBaseCalculo: 0.00 },
      "SP": { aliquota: 0.18, reducaoBaseCalculo: 0.00 },
      "SE": { aliquota: 0.18, reducaoBaseCalculo: 0.00 },
      "TO": { aliquota: 0.18, reducaoBaseCalculo: 0.00 },
    };

    this._tabela1 = [
      {uf: "AC", aliquota: 0.17, reducaoBaseCalculo: 0.00 },
      {uf: "AL", aliquota: 0.18, reducaoBaseCalculo: 0.00 },
      {uf: "AP", aliquota: 0.18, reducaoBaseCalculo: 0.00 },
      {uf: "AM", aliquota: 0.18, reducaoBaseCalculo: 0.00 },
      {uf: "BA", aliquota: 0.18, reducaoBaseCalculo: 0.00 },
      {uf: "CE", aliquota: 0.18, reducaoBaseCalculo: 0.00 },
      {uf: "DF", aliquota: 0.18, reducaoBaseCalculo: 0.00 },
      {uf: "ES", aliquota: 0.17, reducaoBaseCalculo: 0.00 },
      {uf:"GO", aliquota: 0.17, reducaoBaseCalculo: 0.00 },
      {uf:"MA", aliquota: 0.18, reducaoBaseCalculo: 0.00 },
      {uf:"MT", aliquota: 0.17, reducaoBaseCalculo: 0.00 },
      {uf:"MS", aliquota: 0.17, reducaoBaseCalculo: 0.00 },
      {uf:"MG", aliquota: 0.18, reducaoBaseCalculo: 0.00 },
      {uf:"PA", aliquota: 0.17, reducaoBaseCalculo: 0.00 },
      {uf:"PB", aliquota: 0.18, reducaoBaseCalculo: 0.00 },
      {uf:"PR", aliquota: 0.18, reducaoBaseCalculo: 0.00 },
      {uf:"PE", aliquota: 0.18, reducaoBaseCalculo: 0.00 },
      {uf:"PI", aliquota: 0.18, reducaoBaseCalculo: 0.00 },
      {uf:"RJ", aliquota: 0.20, reducaoBaseCalculo: 0.00 },
      {uf:"RN", aliquota: 0.18, reducaoBaseCalculo: 0.00 },
      {uf:"RS", aliquota: 0.18, reducaoBaseCalculo: 0.00 },
      {uf:"RO", aliquota: 0.17, reducaoBaseCalculo: 0.00 },
      {uf:"RR", aliquota: 0.17, reducaoBaseCalculo: 0.00 },
      {uf:"SC", aliquota: 0.17, reducaoBaseCalculo: 0.00 },
      {uf:"SP", aliquota: 0.18, reducaoBaseCalculo: 0.00 },
      {uf:"SE", aliquota: 0.18, reducaoBaseCalculo: 0.00 },
      {uf:"TO", aliquota: 0.18, reducaoBaseCalculo: 0.00 },
    ];
  }

  gettabela1() {
    return this._tabela1;
  }

  getAliquota(estado) {
    return this.tabela[estado].aliquota || 0.00; // retorna a alíquota do estado informado ou 0 se não encontrado
  }
  getReducaoBaseCalculo(estado) {
    return this.tabela[estado].reducaoBaseCalculo || 0.00; // retorna a redução de base de cálculo do estado informado ou 0 se não encontrado
  }

  get pBcICMS() {
    return this._pBcICMS;
  }

  set pBcICMS(value) {
    this._pBcICMS = value;
  }

  get pIPI() {
    return this._pIPI;
  }

  set pIPI(value) {
    this._pIPI = value;
  }

  get pBC_PIS() {
    return this._pBC_PIS;
  }

  set pBC_PIS(value) {
    this._pBC_PIS = value;
  }

  get pbc_IPI() {
    return this._pbc_IPI;
  }

  set pbc_IPI(value) {
    this._pbc_IPI = value;
  }

  get pPIS() {
    return this._pPIS;
  }

  set pPIS(value) {
    this._pPIS = value;
  }
  get pBc_COFINS() {
    return this._pBc_COFINS;
  }

  set pBc_COFINS(value) {
    this._pBc_COFINS = value;
  }
  get pCOFINS() {
    return this._pCOFINS;
  }
  set pCOFINS(value) {
    this._pCOFINS = value;
  }
  get pICMS() {
    return this._pICMS;
  }
  set pICMS(value) {
    this._pICMS = value;
  }
}

export default Vendas;
