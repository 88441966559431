<script>
import appConfig from "@/app.config";
import { http } from "@/helpers/easyindustriaapi/config";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
import { listCFOPs } from "../../../../components/defaults/cfops";
import { listOrigens } from "@/components/defaults/origens";
import Controller from "./vendas_controller";
export default {
  page: {
    title: "Produto Vendas",
    meta: [{ name: "description", content: appConfig.description }],
  },
  props: {
    //currentEmpresa: { type: Object },
    //newPedido: { type: Object },
    // listTabelas: { type: Object },
    oldPedido: { type: Object },
    sub: { type: Array },
    Produtos: { type: Array },
    list_cfop: { type: Array },
    tem_desconto: { type: Boolean },
    produto_atual: { type: Object },
    destinatario: { type: Array },
    empresa_origem: {type: Object}
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      vendas: new Controller(),
      descricao2: null,
      titleBody: "Produto Vendas",
      submitted: false,
      pedido: {},
      listFornecedores: ["10023", "23123", "10023", "23123", "10023", "23123"],
      listTiposPagamento: [
        {
          id: 0,
          name: "Dinheiro",
        },
      ],
      codBBar: null,
      selecionado: null, // converte de array para variavel;
      CFOP: ["1324", "1231", "1233"],
      quantidade: null,
      preco: null,
      subTotal: null,
      desconto: null,
      Porcentagem: null,
      sub1: null,
      sit_tributaria: null,
      recebe_prodtos: {},
      TabelaICMS: {},
      busca: "",
      produtos: [
        {
          id: 1,
          nome: "Camiseta",
          preco: 29.9,
          label: "Camiseta",
          CFOP: "1234",
        },
        {
          id: 2,
          nome: "Calça Jeans",
          preco: 89.9,
          label: "Calça Jeans",
          CFOP: "1334",
        },
        { id: 3, nome: "Tênis", preco: 129.9, label: "Tênis", CFOP: "1244" },
        {
          id: 4,
          nome: "Jaqueta de Couro",
          preco: 399.9,
          label: "Jaqueta de Couro",
          CFOP: "12345",
        },
        { id: 5, nome: "Bermuda", preco: 39.9, label: "Bermuda", CFOP: "1224" },
        {
          id: 6,
          nome: "Sapato Social",
          preco: 189.9,
          label: "Sapato Social",
          CFOP: "1214",
        },
      ],
      regime: [
        {
          id: 1,
          nome: "Simples Nacional"
        },
        {
          id: 2,
          nome: "Simples Nacional Excesso de Receita Bruta"
        },
        {
          id: 3,
          nome: "Regime Normal"
        }
      ],
      Base: [
        {
          id: 1,
          nome: "Margem Valor Agregado(%)"
        },
        {
          id: 2,
          nome: "Pauta (Valor)"
        },
        {
          id: 3,
          nome: "Preço Tabelado Max. (Valor)"
        },
        {
          id: 4,
          nome: "Valor da Operação"
        }
      ],
      titulo: "CSOM",
      products: [],
      produto_cst: null,
      lista_origem: [], // array de Objetos
      regimeSelecionado: null,
      origemSelecionado: null,
      mod_bdicsm: null,
      reducao_bc_icms: null,
      codigo_de_enquadramento: null,
      aliquota: null, // aliquota de saida do ICMS 
      aliquota_ipi: null,// aliquota de saida do IPI
      aliquota_pis: null,
      aliquota_cofins: null,
      CFOPs1: [],
      list_produtos: [],
      // criados para resolver o bug do CFOPS;
      list2_cfop: [],
      cfop2: null,
      base_icms: null,
      vlr_icms: null,
      base_ipi: null,
      valor_ipi: null,
      base_pis: null,
      valor_pis: null,
      base_confins: null,
      valor_confins: null,
      pedido_sit_trib: null,
      verifica_desconto: null,
      produto_at: null,
      dest_atual: null,
      linha: null,
      coluna: null,
      empresa_orig:null,
    };
  },
  created() {
    this.empresa_orig =this.empresa_origem
    this.verifica_desconto = this.tem_desconto
    this.produto_at = this.produto_atual
    this.dest_atual = this.destinatario
    this.currentEmpresa = JSON.parse(localStorage.getItem('currentEmpresa'));
  },
  validations() {
    return {
      pedido: {
        // tpnf: { required },
        nnf: { required },
        CFOP: { required },
        Unitario: { required },
        Quantidade: { required },
        Desconto: { required },
      },
    };
  },
  mounted() {
    this.setNewTitle();
    //console.log(this.oldPedido)
    this.pedido = this.oldPedido;
    //console.log(this.pedido);
    this.recebe_prodtos = this.sub[0]; //  pega os produtos de teste que
    // faz uma requisição get;
    this.cfop2 = this.pedido.CFOP
    this.descricao2 = this.pedido.descricao;
    //this.checa_produto();
    // this.products = this.Produtos[this.pedido.id - 1];
    //  console.log(this.products)
    this.produto_cst = this.products.produto_cste;
    this.origemSelecionado = this.products.produto_orig
    this.reducao_bc_icms = this.products.produto_pbc_icms
    this.codigo_de_enquadramento = this.products.produto_cenqe // codido de enquadramento IPI saida 
    // this.aliquota = this.products.produto_picmse // aliquota de saida do produto;
    // this.aliquota_ipi = this.products.produto_pipie // ipi de saida do produto;
    // this.aliquota_pis = this.products.produto_ppise // aliquota de saida que foi informada no Produto
    // this.aliquota_cofins = this.products.produto_pcofinse // aliquota de saidado cofins;
    //  console.log(this.products);
    this.CFOPs1 = listCFOPs;
    this.lista_origem = listOrigens
    this.filtar_produtos()
    this.list2_cfop = this.list_cfop
    // this.calcularValorBruto()
    //console.log("tem o descto :" + this.verifica_desconto);
    this.calcularVlrBc1()
    this.calcularVlrIcms()
    this.calculovBC_vBC_IPI()
    this.calculovBC_vIPI()
    this.calculovBC_PIS()
    this.calculo_vPIS()
    this.calculo_vBC_COFINS()
    this.calculo_vCOFINS()
    this.verificar_empresa_destinatario()
   // console.log(this.produto_at)
    this.aliquota = this.produto_at.produto_picmse
    // passar a pessoa quando selecionar o cliente no compente teste;
   // console.log(this.dest_atual[0].pes_uf)
    this.verificar_empresa_destinatario()
    console.log(this.empresa_orig)
  },

  computed: {
    produtosFiltrados() {
      return this.produtos.filter((produto) =>
        produto.nome.toLowerCase().includes(this.busca.toLowerCase())
      );
    },
    // resultado() {
    //   // subtotal;
    //   //(this.oldPedido.Unitario -(this.oldPedido.Unitario * this.oldPedido.Desconto/100)) * this.oldPedido.Quantidade
    //   if (
    //     this.pedido.Unitario == 0 ||
    //     this.pedido.Desconto == 0 ||
    //     this.pedido.Quantidade == 0
    //   ) {
    //     return 0;
    //   }
    //   return (
    //     parseFloat(this.pedido.Unitario) -
    //     ((parseFloat(this.pedido.Unitario) * parseFloat(this.pedido.Desconto)) /
    //       100) *
    //     parseFloat(this.pedido.Quantidade)
    //   );
    // },

    // {$REGION 'ICMS'}
    // FItemVenda.CST      := FIProduto.CSTE;
    // FItemVenda.pBcICMS  := FIProduto.pBcICMSE;
    // FItemVenda.pICMS    := FIProduto.pICMSE;

    // calcularVlrBc1() {
    //   // aguardando Objipeto;
    //   //  if(this.resultado > 0){
    //   //   // return  this.resultado - (this.SubTotal * (TabelaICMS.pRedBC/100))
    //   //  }
    //   //  else {
    //   //   //FItemVenda.VlrBc := FItemVenda.SubTotal;
    //   //  }

    //   //console.log(this.vendas.getReducaoBaseCalculo("PE"));

    //   if (this.vendas.tabela1 == null) {
    //     return this.resultado + this.vendas.pBcICMS / 100;
    //   } else {
    //     if (this.vendas.tabela.reducaoBaseCalculo > 0) {
    //       return (
    //         this.resultado -
    //         this.resultado * (this.vendas.tabela.reducaoBaseCalculo / 100)
    //       );
    //     } else {
    //       return this.resultado;
    //     }
    //     // FItemVenda.pICMS :=  FItemVenda.TabelaICMS.pICMS;
    //     // Na tabela tabém tem uma colunas com as porcentagem ICMS ?
    //     // nos produtos tem um tablela ICMS
    //   }
    // },

    // calcularVlrIcms() {
    //   //   if not(FItemVenda.pICMS <0) then
    //   // begin
    //   //   FItemVenda.vICMS := FItemVenda.VlrBc * (FItemVenda.pICMS / 100);
    //   // end else begin
    //   //   FItemVenda.pICMS   := 0;
    //   //   FItemVenda.vICMS   := 0;
    //   // end;

    //   if (this.vendas.pICMS > 0) {
    //     return this.calcularVlrBc1 * (this.vendas.pICMS / 100);
    //   } else {
    //     this.altera(); // seta o valor zero para PICMS
    //     return 0;
    //   }
    // },
    // regio IPI
    // FItemVenda.CSTIPI  := FIProduto.CSTIPIE;
    // FItemVenda.pBC_IPI := FIProduto.pBCIPIE;
    // FItemVenda.pIPI    := FIProduto.pIPIE;

    // calculovBC_vBC_IPI() {
    //   //Base IpI
    //   if (this.vendas.pbc_IPI > 0) {
    //     return this.resultado * (this.vendas.pbc_IPI / 100);
    //   } else {
    //     return 0; // ver  oque colocar aqui;
    //   }
    // },

    // calculovBC_vIPI() {
    //   //valor do IpI // pipi vem de um obejto de produto;
    //   if (this.vendas.pbc_IPI > 0) {
    //     return this.calculovBC_vBC_IPI * (this.vendas.pIPI / 100);
    //   } else {
    //     return 0;
    //   }
    // },

    //{$REGION 'PIS'}
    // FItemVenda.CstPIS  := FIProduto.CST_pisE;
    // FItemVenda.pBC_PIS := FIProduto.pBCPISE;
    // FItemVenda.pPIS    := FIProduto.pPISE;
    // if not(FItemVenda.pBC_PIS < 0) then
    // calculovBC_PIS() {
    //   // this.resultado == Subtotal;
    //   // pBC_PIS vemo do objeto produto;
    //   if (this.vendas.pBC_PIS > 0) {
    //     return this.resultado * (this.vendas.pBC_PIS / 100);
    //   } else {
    //     // chamar uma função para setar zero no objeto vendas;
    //     this.alterar_pBC_PIS();
    //     return 0; // ver oque colocar aqui;
    //   }
    // },
    // calculo_vPIS() {
    //   if (this.vendas.pBC_PIS > 0) {
    //     return this.calculovBC_PIS * (this.vendas.pPIS / 100);
    //   } else {
    //     // chamar uma função para setar zero no objeto;
    //     this.alterar_vPIS();
    //     return 0;
    //   }
    // }, // ELSE  FItemVenda.vBC_PIS := 0; FItemVenda.pBC_PIS := 0; FItemVenda.vPIS   := 0;

    // {$REGION 'COFINS'}
    // FItemVenda.CstCOFINS  := FIProduto.CST_cofinsE;
    // FItemVenda.pBC_COFINS := FIProduto.pBcCOFINSE;
    // FItemVenda.pCOFINS    := FIProduto.pCOFINSE;
    //  if not(FItemVenda.CSTcofins = '') then
    //  _cboxCST_COFINS.ItemIndex := _cboxCST_COFINS.Items.IndexOf(FItemVenda.CSTcofins);
    //  if not(FItemVenda.pBc_COFINS < 0) then
    //  begin

    // calculo_vBC_COFINS() {
    //   if (this.vendas.pBc_COFINS > 0) {
    //     return this.resultado * (this.vendas.pBc_COFINS / 100);
    //   } else {
    //     // criar uma func para zerar vb_confins pBc_COFINS/
    //     return 0;
    //   }
    // },
    // calculo_vCOFINS() {
    //   // busca o valor Pconfins

    //   if (this.vendas.pBc_COFINS > 0) {
    //     return this.calculo_vBC_COFINS * (this.vendas.pCOFINS / 100);
    //   } else {
    //     return 0; // criar função para zerar o  vConfins ,
    //     // que ainda não tem no Objeto vendas;
    //   }
    // },
    // end else begin
    // FItemVenda.vBC_COFINS := 0;
    // FItemVenda.pBc_COFINS := 0;
    // FItemVenda.vCOFINS    := 0;
    // end;
  },
  methods: {
    filtar_produtos() {
      let vetor_produto = this.Produtos.map(objeto => objeto.produto_nome);
      this.list_produtos = vetor_produto
      //   console.log(this.listCFOP);
    },
    verificar_empresa_destinatario() {
      var tabela_icms = [
        ['*', 'AC', 'AL', 'AM', 'AP', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RN', 'RS', 'RJ', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO', 'IM'],
        ['AC', 17, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 4],
        ['AL', 8, 18, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 4],
        ['AM,', 8, 9, 18, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 4],
        ['AP', 8, 9, 9, 18, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 4],
        ['BA', 8, 9, 9, 9, 18, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 4],
        ['CE', 8, 9, 9, 9, 9, 18, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 4],
        ['DF', 8, 9, 9, 9, 9, 9, 18, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 4],
        ['ES', 8, 9, 9, 9, 9, 9, 9, 17, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 4],
        ['GO', 8, 9, 9, 9, 9, 9, 9, 9, 17, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 4],
        ['MA', 8, 9, 9, 9, 9, 9, 9, 9, 9, 18, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 4],
        ['MT', 8, 9, 9, 9, 9, 9, 9, 9, 9, 9, 17, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 4],
        ['MS', 8, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 17, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 4],
        ['MG', 8, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 18, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 4],
        ['PA', 8, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 17, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 4],
        ['PB', 8, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 18, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 4],
        ['PR', 8, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 18, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 4],
        ['PE', 8, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 18, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 4],
        ['PI', 8, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 18, 9, 9, 9, 9, 9, 9, 9, 9, 9, 4],
        ['RN', 8, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 18, 9, 9, 9, 9, 9, 9, 9, 9, 4],
        ['RS', 8, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 18, 9, 9, 9, 9, 9, 9, 9, 4],
        ['RJ', 8, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 20, 9, 9, 9, 9, 9, 9, 4],
        ['RO', 8, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 17.5, 9, 9, 9, 9, 9, 4],
        ['RR', 8, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 17, 9, 9, 9, 9, 4],
        ['SC', 8, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 17, 9, 9, 9, 4],
        ['SP', 8, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 18, 9, 9, 4],
        ['SE', 8, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 18, 9, 4],
        ['TO', 8, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 18, 4],
        ['IM', 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
      ];
     // console.log(tabela_icms)
      //console.log(this.dest_atual[0].pes_uf)
      // if (this.dest_atual[0].pes_uf == 'BA' && this.currentEmpresa.uf == 'BA') {
      //   console.log(this.dest_atual[0].pes_uf)
      //   this.aliquota = "18%"
      // }
      // origem 
      console.log(this.empresa_orig.uf + " empresa_uf")
      for (let i=0; i <= 28; i++) {
        if (this.empresa_orig.uf == tabela_icms[i][0]) {
          //console.log(tabela_icms[i][0])
          console.log('linha' + i)
          this.linha =i
        }
      }
      // destino 
      let destimatario = this.dest_atual[0].pes_uf
      for (let j = 0; j <= 28; j++) {
        if(destimatario == tabela_icms[0][j]){
          //console.log(tabela_icms[0][j])
         // console.log('coluna' + j)
          this.coluna = j
        }
      }
          // console.log(tabela_icms[this.linha][this.coluna])
        // console.log(this.linha + '' + this.coluna);
         this.aliquota = tabela_icms[this.linha][this.coluna] + '%'
    },


    atualizar_desc(descricao) {
      console.log(descricao);
      // this.descricao2 = descricao;
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    altera() {
      this.vendas.pICMS = 0;
    },
    checa_produto() {
      // console.log(this.Produtos)
      let produtos = this.Produtos
      let pedidos = this.pedido
      //  console.log(pedidos.descrição)
      //  console.log(produtos[0].produto_descricao)

      produtos.forEach((produto, index) => {

        if (produto.produto_descricao.toUpperCase() == pedidos.descrição.toUpperCase()) {

          //console.log(index)
          this.products = this.Produtos[index]
        }
      })
    },
    cath_name(regime) {
      //console.log(regime);
      if (regime.id == 1) {
        this.titulo = "CSOSN"
      } else if (regime.id == 2) {
        this.titulo = "CSOSN"
      } else if (regime.id == 3) {
        this.titulo = "CST"
      } else {
        this.titulo = "CSOSN"
      }
    },
    setNewTitle() {
      this.$emit("newTitle", this.titleBody);
    },
    getData() {
      this.$emit("onLoader");
      http.get("/pedido/" + this.pedido.id + "/item");
      this.$emit("offLoader");
    },
    selectFornecedor(value) {
      this.pedido.pessoa_id = value ? value.id : null;
    },
    selectTipoPagamento(value) {
      this.pedido.tipopgto_id = value ? value.id : null;
    },
    selectCFOP(value) {
      this.pedido.cfop = value ? value.id : null;
    },
    selectHistoricoPdr(value) {
      this.pedido.historicopdr_id = value ? value.id : null;
    },
    selectTpMovimento(value) {
      this.pedido.tpmovimento_id = value ? value.id : null;
      var cfop = null;
      if (value) {
        if (this.currentEmpresa.uf === this.fornecedorSelecionado.uf) {
          cfop = value.cfop.replace("*", "1").replace(".", "");
        } else {
          cfop = value.cfop.replace("*", "2").replace(".", "");
        }
        this.pedido.serie = value.serie;
        this.pedido.cfop = cfop;
        this.CFOPs.filter((item) => {
          if (item.id === parseInt(cfop)) {
            this.cfopSelecionado = item;
          }
        });
      }
    },
    selectEstoqueOrigem(value) {
      this.pedido.loc_origem_id = value ? value.id : null;
      if (value) {
        this.listLocaisEstoque.filter((item) => {
          if (item.id === value.id) {
            this.estoqueOrigemSelecionado = item;
          }
        });
      }
    },
    selectEstoqueDestino(value) {
      this.pedido.loc_id = value ? value.id : null;

      if (value) {
        this.listLocaisEstoque.filter((item) => {
          if (item.id === value.id) {
            this.estoqueDestinoSelecionado = item;
          }
        });
      }
    },
    // eslint-disable-next-line no-unused-vars
    formPedido(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.pedido.$error) {
        this.makeToast("danger", "Erro de validação!");
      } else {
        this.$emit("doPost", this.pedido);
      }
    },
    formatInput_valor_unit() {
      //console.log(index)
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.pedido.Unitario.replace(/\D/g, '');
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.pedido.Unitario = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).replace("R$", " ");
    },

    formatInput_desc2() {
      // console.log(index)
      let Desconto = this.pedido.Desconto
      //console.log(this.pedido.Desconto)
      if (Desconto.includes('%')) {
        console.log(isNaN(this.pedido.Desconto));
        //  nem percisava repeditr duas vezes 
        if (Desconto.includes('%')) {
          setTimeout(function () {
            // O código que você quer executar após o atraso de 3 segundos vai aqui
            let numericValue = this.pedido.Desconto.replace(/\D/g, '')
            this.pedido.Desconto = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).replace("R$", " ");
          }, 300);
          this.calcularValorBruto()
        } else {
          //this.tem_desconto = false;
          this.calcularValorBruto2();
        }
      } else {
        //this.tem_desconto = false;
        let numericValue = this.pedido.Desconto.replace(/\D/g, '');
        this.pedido.Desconto = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).replace("R$", " ");
        this.calcularValorBruto2();
      }
    },
    formatInput_Desc() {
      //console.log(index)
      // Remova todos os caracteres não numéricos do valor de entrada
      // this.verifica_desconto
      //let verific_dec = this.verifica_desconto
      let numericValue = this.pedido.Desconto.replace(/\D/g, '');
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.pedido.Desconto = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).replace("R$", " ");
      // console.log(this.pedido.Desconto)
    },
    calcularVlrBc1() {

      // console.log(this.pedido.Bruto)
      //  console.log(this.vendas.pBcICMS )
      // produto_at.produto_pbc_icms
      // produto_pbc_cofins
      // produto_pBC_pisst
      // todos Esses impstos que começam com p, vemo do produtos;
      // produto_cest
      // verificar as inforamções do produto para setar aqui nesasa tela de alterar 
      // pedido;

      if (this.vendas.tabela1 == null) {
        this.base_icms = this.pedido.Bruto + this.produto_at.produto_pbc_icms / 100
      } else {
        if (this.vendas.tabela.reducaoBaseCalculo > 0) {
          this.base_icms = this.resultado - this.resultado * (this.vendas.tabela.reducaoBaseCalculo / 100).toFixed(2)
        } else {
          this.base_icms = this.pedido.Bruto;
        }
      }
    },
    calcularVlrIcms() {
      //console.log('ENtrou on VLr ICMS')
      //  parseFloat
      // vemo do produto;
      if (this.produto_at.produto_picms > 0) {
        //console.log("vl_ICMS")
        //console.log(this.base_icms)
        //console.log(this.vendas.pICMS)
        this.vlr_icms = parseFloat(this.base_icms) * parseFloat(this.produto_at.produto_picms / 100);
      } else {
        //console.log("entrou no else")
        //this.altera(); // seta o valor zero para PICMS
        this.vlr_icms = 0;
      }
    },
    calculovBC_vBC_IPI() {
      console.log(typeof (this.pedido.Bruto))
      // let brutt = '1.000,00'
      //   if(brutt.includes(',') && brutt.includes('.')){
      //    // let numeric23 = brutt.replace(',', '');
      //     console.log(parseFloat(numeric23))
      //   }else {
      //     let numeric22 = brutt.replace(',', '.');
      //   //  console.log(numeric22)
      //   }
      if (typeof (this.pedido.Bruto) === "string") {
        let numeric = this.pedido.Bruto;
        // if (numeric.includes(',') && numeric.includes('.')) {
        //   numeric = this.pedido.Bruto.replace(',', '')
        // } else {
        //   numeric = this.pedido.Bruto.replace(',', '.')
        // }
        var vlr1_bruto = parseFloat(numeric)
        console.log(vlr1_bruto)
        //console.log(numeric)
        if (this.vendas.pbc_IPI >= 0) {
          //console.log(this.pedido.Bruto)
          this.base_ipi = vlr1_bruto * (this.produto_at.produto_pbc_ipi / 100);
          //console.log(this.base_ipi)
        } else {
          this.base_ipi = 0; // ver  oque colocar aqui;
        }
      } else {
        if (this.vendas.pbc_IPI >= 0) {
          console.log(this.pedido.Bruto)
          this.base_ipi = this.pedido.Bruto * (this.produto_at.produto_pbc_ipi / 100);
        } else {
          this.base_ipi = 0; // ver  oque colocar aqui;
        }
      }

    },
    calculovBC_vIPI() {
      //valor do IpI // pipi vem de um obejto de produto;
      if (this.vendas.pbc_IPI > 0) {
        this.valor_ipi = this.base_ipi * (this.produto_at.produto_pipi / 100);
      } else {
        this.valor_ipi = 0;
      }
    },
    calculovBC_PIS() {
      ///console.log("entrou PIS BC")
      if (typeof (this.pedido.Bruto) === "string") {
        let numeric = this.pedido.Bruto.replace(',', '.');
        var vlr1_bruto2 = parseFloat(numeric)
        if (this.vendas.pBC_PIS > 0) {

          this.base_pis = vlr1_bruto2 * (this.produto_at.produto_pbc_pis / 100);
        } else {
          // chamar uma função para setar zero no objeto vendas;
          this.alterar_pBC_PIS();
          this.base_pis = 0; // ver oque colocar aqui;
        }
      } else {
        if (this.vendas.pBC_PIS > 0) {
          this.base_pis = this.pedido.Bruto * (this.produto_at.produto_pbc_pis / 100);
        } else {
          // chamar uma função para setar zero no objeto vendas;
          this.alterar_pBC_PIS();
          this.base_pis = 0; // ver oque colocar aqui;
        }
      }
    },
    calculo_vPIS() {
      if (this.vendas.pBC_PIS > 0) {
        this.valor_pis = this.base_pis * (this.produto_at.produto_ppis / 100);
      } else {
        // chamar uma função para setar zero no objeto;
        this.alterar_vPIS();
        this.valor_pis = 0;
      }
    },
    calculo_vBC_COFINS() {
      if (typeof (this.pedido.Bruto) === "string") {
        let numeric = this.pedido.Bruto.replace(',', '.');
        var vlr1_bruto3 = parseFloat(numeric)
        if (this.vendas.pBc_COFINS > 0) {
          this.base_confins = vlr1_bruto3 * (this.produto_at.produto_pbc_cofins
            / 100);
        } else {
          // criar uma func para zerar vb_confins pBc_COFINS/
          this.base_confins = 0;
        }
      } else {
        if (this.vendas.pBc_COFINS > 0) {
          this.base_confins = this.pedido.Bruto * (this.produto_at.produto_pbc_cofins
            / 100);
        } else {
          // criar uma func para zerar vb_confins pBc_COFINS/
          this.base_confins = 0;
        }
      }

    },
    calculo_vCOFINS() {
      if (this.vendas.pBc_COFINS > 0) {
        this.valor_confins = this.base_confins * (this.produto_at.produto_pcofins / 100);
      } else {
        this.valor_confins = 0; // criar função para zerar o  vConfins ,
        // que ainda não tem no Objeto vendas;
      }
    },
    calcularValorBruto() {
      //console.log("entoru no 1")
      const item = this.pedido;
      if (item.Unitario == "" || item.Desconto == "") {
        this.pedido.Bruto = 0;
      } else if (item.Unitario != "" && item.Desconto == '0,00') {
        this.pedido.Bruto = item.Bruto
      } else {
        let num = item.Desconto
        let num1 = num.replace(',', '.')
        //console.log(item.Unitario)
        let num_uni = item.Unitario
        let num_uni_res = num_uni.replace(/\./g, "")
        let num_uni_res2 = num_uni_res.replace(",", ".")
        // let num_uni = item.Unitario.replace(",", ".")
        // let num_uni_res = num_uni
        //console.log(num_uni_res2)
        // if (num_uni_res.includes(',') && num_uni_res.includes('.')) {
        //   num_uni_res = num_uni.replace(',', '')
        // } else {
        //   num_uni_res = num_uni.replace(',', '.')
        // }
        // console.log(parseFloat(num_uni_res))
        let valorUnitario = parseFloat(num_uni_res2);
        console.log(valorUnitario)
        const desconto = parseFloat(num1);
        // console.log(desconto)
        const quantidade = parseInt(item.Quantidade);
        // console.log(quantidade)
        const valorBruto = (valorUnitario * quantidade) * (1 - desconto / 100).toFixed(4);
        // console.log(valorBruto)
        // base de calculo 
        this.pedido.Bruto = this.formatterCurrBR(valorBruto);

      }
      this.calcularVlrBc1()
      this.calcularVlrIcms()
      this.calculovBC_vBC_IPI()
      this.calculovBC_vIPI()
      this.calculovBC_PIS()
      this.calculo_vPIS()
      this.calculo_vBC_COFINS()
      this.calculo_vCOFINS()
    },
    calcularValorBruto2() {

      // console.log("entoru no 2")
      const item = this.pedido;
      if (item.Unitario == "" || item.Desconto == "") {
        this.pedido.Bruto = 0;
      } else if (item.Unitario != "" && item.Desconto == '0,00') {
        this.pedido.Bruto = item.Bruto
      } else {
        let num = item.Desconto
        let num1 = num.replace(/\./g, "")
        console.log(num1)
        let num3 = num1.replace(',', '.')
        //console.log(parseFloat(num1))
        // let num_uni = item.Unitario
        // let num_uni_res = num_uni.replace(',', '.')
        let num_uni = item.Unitario
        let num_uni_res = num_uni.replace(/\./g, "")
        let num_uni_res2 = num_uni_res.replace(",", ".")
        // if (num_uni_res.includes(',') && num_uni_res.includes('.')) {
        //   num_uni_res = num_uni.replace(',', '')
        //   //console.log(num_uni_res)
        // } else {
        //   num_uni_res = num_uni.replace(',', '.')
        //   // console.log(num_uni_res)
        // }
        //console.log(parseFloat(num_uni_res))
        const valorUnitario = parseFloat(num_uni_res2);
        const desconto = parseFloat(num3);
        //console.log(desconto)
        const quantidade = parseInt(item.Quantidade);
        //console.log(quantidade)
        const valorBruto = (valorUnitario * quantidade) - desconto.toFixed(4);
        // console.log(valorBruto)
        // base de calculo 
        //console.log(valorBruto)
        this.pedido.Bruto = this.formatterCurrBR(valorBruto);
        //  console.log(this.items1[index].Bruto)
      }
      this.calcularVlrBc1()
      this.calcularVlrIcms()
      this.calculovBC_vBC_IPI()
      this.calculovBC_vIPI()
      this.calculovBC_PIS()
      this.calculo_vPIS()
      this.calculo_vBC_COFINS()
      this.calculo_vCOFINS()
    },
  },
};
</script>
<template>
  <div class="card">
    <div class="accordion" role="tablist">
      <form role="form" class="form-horizontal">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.pedido-dados-gerais variant="light">Produtos Vendas</b-button>
          </b-card-header>
          <b-collapse id="pedido-dados-gerais" visible accordion="pedido-accordion" role="tabpanel">
            <b-card-body>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-2" id="cod_prod">
                  <div class="form-group">
                    <label for="pedido_id" class="col-form-label">Código Produto</label>
                    <div class="col">
                      <input disabled v-model="pedido.id" class="form-control text-right" type="text"
                        placeholder="Gerado pelo sistema" id="pedido_id" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-4" id="desc_ID">
                  <div class="form-group">
                    <label for="pessoa_id" class="col-form-label">Descricao</label>
                    <div class="col">
                      <multiselect v-model="descricao2" :options="list_produtos"
                        placeholder="Buscar ou selecionar produtos...">
                        <!-- o Event manda o um objeto produto para o metodo -->
                      </multiselect>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="CFOP_id" class="col-form-label" id="CFOP_ID">CFOP</label>
                    <div class="col" id="CFOP_ID">
                      <multiselect v-model="cfop2" :options="list2_cfop" :clear-on-select="true" :preserve-search="true"
                        placeholder="Selecione" :class="{
                          'is-invalid': submitted && $v.pedido.CFOP.$error,
                        }">
                      </multiselect>
                      <div v-if="submitted && $v.pedido.CFOP.$error" class="invalid-feedback">
                        <span v-if="!$v.pedido.CFOP.required">
                          {{ $t("mesages.validation.required") }}</span>
                      </div>
                      <!--<b-form-invalid-feedback v-if="submitted && $v.pedido.pessoa_id.$error" :state="($v.pedido.pessoa_id.required)
                      ">
                        {{ $t('mesages.validation.selectone') }}
                      </b-form-invalid-feedback> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row form-group" role="group" id="espaco">
                <div class="col-sm-12 col-md-2">
                  <div class="form-group" id="espaco1">
                    <label for="quatidade_id" class="col-form-label">Quantidade</label>
                    <div class="col">
                      <input v-model="pedido.Quantidade" @input="calcularValorBruto()" type="number" min="1"
                        class="form-control text-right" placeholder="Qauntidade" id="quantidade" :class="{
                          'is-invalid':
                            submitted && $v.pedido.Quantidade.$error,
                        }" />
                      <div v-if="submitted && $v.pedido.Quantidade.$error" class="invalid-feedback">
                        <span v-if="!$v.pedido.Quantidade.required">
                          {{ $t("mesages.validation.required") }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-2">
                  <div class="form-group">
                    <label for="preco" class="col-form-label">Valor Unitario
                    </label>
                    <div class="col">
                      <input v-model="pedido.Unitario" @input="formatInput_valor_unit(), calcularValorBruto()"
                        class="form-control text-right" placeholder="digite preco" id="preco_id" :class="{
                          'is-invalid': submitted && $v.pedido.Unitario.$error,
                        }" />
                      <div v-if="submitted && $v.pedido.Unitario.$error" class="invalid-feedback">
                        <span v-if="!$v.pedido.Unitario.required">
                          {{ $t("mesages.validation.required") }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-2">
                  <div class="form-group">
                    <label for="desconto" class="col-form-label">Desconto</label>
                    <div class="col">
                      <input v-model="pedido.Desconto" @input="formatInput_desc2()" type="text"
                        class="form-control text-right" placeholder="Desconto" id="desc_id" :class="{
                          'is-invalid': submitted && $v.pedido.Desconto.$error,
                        }" />
                      <div v-if="submitted && $v.pedido.Desconto.$error" class="invalid-feedback">
                        <span v-if="!$v.pedido.Desconto.required">
                          {{ $t("mesages.validation.required") }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-2">
                  <div class="form-group">
                    <label for="sub" class="col-form-label">Sub Total</label>
                    <div class="col">
                      <input v-model="pedido.Bruto" disabled type="text" class="form-control text-right"
                        placeholder="Sub total" id="sub_id" />
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
          <!-- Menus de Impostos-->
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.pedido-complementares variant="light">ICMS</b-button>
          </b-card-header>
          <b-collapse id="pedido-complementares">
            <b-card-body>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="regime" class="col-form-label">Regime</label>
                    <div class="col">
                      <multiselect v-model="regimeSelecionado" :options="regime" :multiple="false" :close-on-select="true"
                        :clear-on-select="true" :preserve-search="true" optionLabel="nome" label="nome" :track-by="'id'"
                        placeholder="Buscar ou selecionar produtos..." @select="cath_name($event)">
                        <!-- o Event manda o um objeto produto para o metodo -->
                        <template slot="option" slot-scope="{ option }">
                          {{ option.nome }}
                        </template>
                      </multiselect>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="CSOSN" class="col-form-label">{{ titulo }}</label>
                    <div class="col">
                      <input v-model="produto_cst" type="text" class="form-control text-left" id="CSOSN" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="Origem" class="col-form-label">Origem</label>
                    <div class="col">
                      <multiselect v-model="origemSelecionado" :options="lista_origem" :multiple="false"
                        :close-on-select="true" :clear-on-select="true" :preserve-search="true" optionLabel="name"
                        label="name" :track-by="'id'" placeholder="selecione a origem...">
                        <!-- o Event manda o um objeto produto para o metodo -->
                        <template slot="option" slot-scope="{ option }">
                          {{ option.name }}
                        </template>
                      </multiselect>
                    </div>
                    <div id="espaco">
                      <p>&nbsp;</p>
                    </div>
                    <label for="BC_ICMS" class="col-form-label">Modalidade Determ. de BC ICMS</label>
                    <div class="col">
                      <multiselect v-model="mod_bdicsm" :options="Base" :multiple="false" :close-on-select="true"
                        :clear-on-select="true" :preserve-search="true" optionLabel="nome" label="nome" :track-by="'id'"
                        placeholder="Buscar ou selecionar...">
                        <!-- o Event manda o um objeto produto para o metodo -->
                        <template slot="option" slot-scope="{ option }">
                          {{ option.nome }}
                        </template>
                      </multiselect>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="base_icms" class="col-form-label">Base ICMS</label>
                    <div class="col">
                      <div class="col">
                        <input v-model="base_icms" type="text" class="form-control text-left" id="icms_id" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="chnfe" class="col-form-label">Valor ICMS</label>
                    <div class="col">
                      <input v-model="vlr_icms" type="text" class="form-control text-left" id="aliq_id" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="chnfe" class="col-form-label">Redução de BC</label>
                    <div class="col">
                      <input v-model="reducao_bc_icms" type="text" class="form-control text-left" id="Red_id" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="v_icms" class="col-form-label">Aliquota</label>
                    <div class="col">
                      <input v-model="aliquota" type="text" class="form-control text-left" id="v_icms_id" />
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
          <!--- ------------ -->
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.pdv-ipi variant="light2">IPI</b-button>
          </b-card-header>
          <b-collapse id="pdv-ipi">
            <b-card-body>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="s_tributaria" class="col-form-label">Situação Tributaria</label>
                    <div class="col">
                      <multiselect :options="regime" :multiple="false" :close-on-select="true" :clear-on-select="true"
                        :preserve-search="true" optionLabel="nome" label="nome" :track-by="'id'"
                        placeholder="Buscar ou selecionar produtos...">
                        <!-- o Event manda o um objeto produto para o metodo -->
                        <template slot="option" slot-scope="{ option }">
                          {{ option.nome }}
                        </template>
                      </multiselect>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="cod_enq" class="col-form-label">Codigo de Enquadramento</label>
                    <div class="col">
                      <input v-model="codigo_de_enquadramento" type="text" class="form-control text-left"
                        id="cod_enq_id" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <div id="espaco1">
                      <p>Valor IPI</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="base_ipi" class="col-form-label">Base IPI R$:</label>
                    <div class="col">
                      <div class="col">
                        <input v-model="base_ipi" type="text" class="form-control text-left" id="b_ipi_id" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="valor_ip" class="col-form-label">Valor do IP %:</label>
                    <div class="col">
                      <input v-model="valor_ipi" type="text" class="form-control text-left" id="v_ip_id" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="aliquota" class="col-form-label">Aliquota do Ipi:</label>
                    <div class="col">
                      <input v-model="aliquota_ipi" type="text" class="form-control text-left" id="Aliq_id" />
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.pdv-pis variant="light">PIS</b-button>
          </b-card-header>
          <b-collapse id="pdv-pis">
            <b-card-body>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="sit_trib" class="col-form-label">Situação Tributaria</label>
                    <div class="col">
                      <multiselect :options="regime" :multiple="false" :close-on-select="true" :clear-on-select="true"
                        :preserve-search="true" optionLabel="nome" label="nome" :track-by="'id'"
                        placeholder="Buscar ou selecionar produtos...">
                        <!-- o Event manda o um objeto produto para o metodo -->
                        <template slot="option" slot-scope="{ option }">
                          {{ option.nome }}
                        </template>
                      </multiselect>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="aliquota" class="col-form-label">Base de Calculo</label>
                    <div class="col">
                      <!--   // base_pis:null,
    //   valor_pis:null -->
                      <input v-model="base_pis" type="text" class="form-control text-left" id="Aliq_id" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="aliquota" class="col-form-label">Valor</label>
                    <div class="col">
                      <input v-model="valor_pis" type="text" class="form-control text-left" id="Aliq_id" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="aliquota" class="col-form-label">Alíquota</label>
                    <div class="col">
                      <input v-model="aliquota_pis" type="text" class="form-control text-left" id="Aliq_id" />
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.pdv-cofins variant="light">COFINS</b-button>
          </b-card-header>
          <b-collapse id="pdv-cofins">
            <b-card-body>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="sit_trib" class="col-form-label">Situação Tributaria
                    </label>
                    <div class="col">
                      <multiselect :options="regime" :multiple="false" :close-on-select="true" :clear-on-select="true"
                        :preserve-search="true" optionLabel="nome" label="nome" :track-by="'id'"
                        placeholder="Buscar ou selecionar produtos...">
                        <!-- o Event manda o um objeto produto para o metodo -->
                        <template slot="option" slot-scope="{ option }">
                          {{ option.nome }}
                        </template>
                      </multiselect>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="aliquota" class="col-form-label">Base de Calculo</label>
                    <div class="col">
                      <!-- base_confins:null,
    //   valor_confins:null -->
                      <input v-model="base_confins" type="text" class="form-control text-left" id="Aliq_id" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="aliquota" class="col-form-label">Valor</label>
                    <div class="col">
                      <input v-model="valor_confins" type="text" class="form-control text-left" id="Aliq_id" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="aliquota" class="col-form-label">Alíquota</label>
                    <div class="col">
                      <input v-model="aliquota_cofins" type="text" class="form-control text-left" id="Aliq_id" />
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.pdv-info-ad variant="light">Informações Adicionais</b-button>
          </b-card-header>
          <b-collapse id="pdv-info-ad">
            <b-card-body>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="sit_tributaria" class="col-form-label">Situação Tributaria</label>
                    <div class="col">
                      <multiselect :options="regime" :multiple="false" :close-on-select="true" :clear-on-select="true"
                        :preserve-search="true" optionLabel="nome" label="nome" :track-by="'id'"
                        placeholder="Buscar ou selecionar produtos...">
                        <!-- o Event manda o um objeto produto para o metodo -->
                        <template slot="option" slot-scope="{ option }">
                          {{ option.nome }}
                        </template>
                      </multiselect>
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.pdv-icms-int variant="light">ICMS Interestadual</b-button>
          </b-card-header>
          <b-collapse id="pdv-icms-int">
            <b-card-body>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="bc_destino" class="col-form-label">BC do FCP na UF destino</label>
                    <div class="col">
                      <input v-model="pedido.chnfe" type="text" class="form-control text-left" id="bc_destino_id" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="fcp_dest" class="col-form-label">%ICMS FCP UF destino</label>
                    <div class="col">
                      <input type="text" class="form-control text-left" id="fcp_id" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="v_icms_fcp" class="col-form-label">Valor ICMS FCP na UF destino</label>
                    <div class="col">
                      <input v-model="pedido.chnfe" type="text" class="form-control text-left" id="v_cfp_id" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="v_uf_bc_icms" class="col-form-label">BC do ICMS da UF de Destino</label>
                    <div class="col">
                      <input type="text" class="form-control text-left" id="bc_icms" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="alq_int" class="col-form-label">Aliquota interestadual</label>
                    <div class="col">
                      <input type="text" class="form-control text-left" id="alq_int_id" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="p_p_icms" class="col-form-label">% provisório de partilha de ICMS*</label>
                    <div class="col">
                      <input type="text" class="form-control text-left" id="p_p_icms_id" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="ali_int_uf" class="col-form-label">Aliquota interna da UF destino</label>
                    <div class="col">
                      <input type="text" class="form-control text-left" id="ali_int_uf_id" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-4">
                  <div class="form-group">
                    <label for="v_icms_int_dest" class="col-form-label">Valor ICMS interestadual para UF destino</label>
                    <div class="col">
                      <input type="text" class="form-control text-left" id="v_icms_int_dest_id" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-4">
                  <div class="form-group">
                    <label for="v_icms_int_rem" class="col-form-label">Valor ICMS interestadual para UF remetente</label>
                    <div class="col">
                      <input type="text" class="form-control text-left" id="v_icms_int_rem_id" />
                    </div>
                  </div>
                </div>
              </div>
              <!-- linha de baixo-->
            </b-card-body>
          </b-collapse>
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.pdv-pc variant="light">Pedido de Compra</b-button>
          </b-card-header>
          <b-collapse id="pdv-pc">
            <b-card-body>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="sit_trib" class="col-form-label">Situação Tributaria</label>
                    <div class="col">
                      <multiselect v-model="pedido_sit_trib" :options="regime" :multiple="false" :close-on-select="true"
                        :clear-on-select="true" :preserve-search="true" optionLabel="nome" label="nome" :track-by="'id'"
                        placeholder="Buscar ou selecionar produtos...">
                        <!-- o Event manda o um objeto produto para o metodo -->
                        <template slot="option" slot-scope="{ option }">
                          {{ option.nome }}
                        </template>
                      </multiselect>
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- engloba  todos-->
      </form>
      <!-- form engloba todos-->
    </div>
    <!--Engloba todos -->

    <div class="card-body">
      <div class="col-md-12 mt-10">
        <button class="btn btn-light" @click="formPedido">
          Gravar Iten pedido
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
#espaco {
  margin-top: 60px;
}

#espaco1 {
  margin-top: 40px;
  font-weight: bold;
}

#CFOP_ID {
  margin-left: 40px;
}

#desc_ID {
  margin-left: 20px;
}

#espaco {
  margin-left: 40px;
}

#espaco1 {
  margin: 0px;
}

#cod_prod {
  margin-left: 40px;
}
</style>